// @ts-nocheck

import React from 'react';
import {DefaultPageLayout} from "./_layouts/DefaultPageLayout";
import {Heading, Link, Text} from "@modbros/components/typography";
import {Flex} from "@modbros/components/layout";
import {Button} from "@modbros/components/form";
import {DownloadIcon} from "@modbros/icons";


export default function Marketplace() {
    return (
        <DefaultPageLayout>
            <Flex justifyContent={'center'} flexDirection={"column"} alignItems="center" height="100%" width="100%">
                <Heading level={1} useTag="h1" headingColor="Emphasized" >Coming soon...</Heading>
                <Text>For now, the marketplace is only inside the app - <Link href="https://update.mobro.app/download/latest" target="_blank" >so go and download it now!</Link></Text>

            </Flex>
        </DefaultPageLayout>
    );
}

