import React from 'react';
import {motion, Variants} from 'framer-motion'

const widgetVariants: Variants = {
    offscreen: {
        y: 25,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.25,
            duration: 1.33
        }
    }
};

const widgetContainerVariants: Variants = {
    offscreen: { opacity: 0 },
    onscreen: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        }
    }
}

const widgetLineVariants: Variants = {
    offscreen: { pathLength: 0 },
    onscreen: {
        pathLength: 0.49,
        transition: { delay: .33, duration: .3, ease: "easeInOut" }
    }
}

const widgetLineVariants2: Variants = {
    offscreen: { pathLength: 0, pathOffset: 0.5001 },
    onscreen: {
        pathLength: 0.49,
        transition: { delay: .33, duration: .66, ease: "easeInOut" }
    }
}

const widgetLineOverlayVariant: Variants = {
    offscreen: { opacity: 0 },
    onscreen: {
        opacity: 1,
        transition: { delay: .5, duration: .5, ease: "easeInOut" }
    }
}

export const Widgets = () => {

    return(
        <motion.svg
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.3}}
            variants={widgetContainerVariants}
            width="583" height="412" viewBox="0 0 583 412" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.g
                variants={widgetVariants}
                className="line-chart" filter="url(#filter0_ddd_406_3176)">
                <rect x="30" y="116" width="417" height="256" rx="8" fill="#343441"/>
                <rect width="417" height="32" transform="translate(30 116)" fill="#1F1F26"/>
                <rect x="38" y="126" width="97" height="12" fill="white"/>
                <rect x="427" y="126" width="12" height="12" fill="#C6C6D2"/>
                <line x1="56" y1="159.5" x2="436" y2="159.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <line x1="56" y1="194.5" x2="436" y2="194.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <line x1="56" y1="229.5" x2="436" y2="229.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <line x1="56" y1="264.5" x2="436" y2="264.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <line x1="56" y1="299.5" x2="436" y2="299.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <line x1="56" y1="334.5" x2="436" y2="334.5" stroke="#1F1F26" stroke-opacity="0.5"/>
                <motion.path
                    variants={widgetLineVariants2}
                    fill="transparent"
                    strokeWidth="6"
                    stroke="#00C0BC"
                    d="M434 292L372 286.5L309 266L246 270L183 261.5L120 246L57.0001 244V240L120 242L183 257L246 266L309 261.5L372 281.5L434 288V292Z"
                    />
                <motion.path
                    variants={widgetLineVariants}
                    fill="transparent"
                    strokeWidth="6"
                    stroke="#FF3579"
                    d="M56.0001 292L120 253L183 232L246 188L309 219L372 225.846L435 191V196L372 230.5L309 223L246 192L183 236L120 257L56.0001 296V292Z"
                />
                <motion.path
                    variants={widgetLineVariants2}
                    fill="transparent"
                    strokeWidth="6"
                    stroke="#FFDD00"
                    d="M434 302L372 310.694L310 293.5L246.5 295.906L183 288.424L120 281L57.0001 269V265L120 277L183 284.424L246.5 291.906L310 289.5L372 306.694L434 298V302Z"
                    />
                <motion.path
                    variants={widgetLineOverlayVariant}
                    d="M251 190C251 192.761 248.761 195 246 195C243.239 195 241 192.761 241 190C241 187.239 243.239 185 246 185C248.761 185 251 187.239 251 190ZM242.932 190C242.932 191.694 244.306 193.068 246 193.068C247.694 193.068 249.068 191.694 249.068 190C249.068 188.306 247.694 186.932 246 186.932C244.306 186.932 242.932 188.306 242.932 190Z"
                    fill="#171721"/>
                <motion.g
                    variants={widgetLineOverlayVariant}
                    filter="url(#filter1_ddd_406_3176)">
                    <motion.path
                        variants={widgetLineOverlayVariant}
                        d="M307 201V176C307 174.343 305.657 173 304 173H278.683H256C254.343 173 253 174.343 253 176V201C253 202.657 254.343 204 256 204H304C305.657 204 307 202.657 307 201Z"
                        fill="#171721" fill-opacity="0.8"/>
                    <motion.rect                         variants={widgetLineOverlayVariant}
                                                         x="257" y="194" width="32" height="6" fill="#C6C6D2"/>
                    <motion.rect                         variants={widgetLineOverlayVariant}
                                                         x="257" y="177" width="45" height="13" fill="white"/>
                </motion.g>
                <rect x="42" y="157" width="12" height="5" fill="#C6C6D2"/>
                <rect x="42" y="332" width="12" height="5" fill="#C6C6D2"/>
                <rect x="42" y="346" width="14" height="14" rx="3" fill="#00C0BC"/>
                <rect x="60" y="347" width="25" height="12" fill="#C6C6D2"/>
                <rect x="89" y="346" width="14" height="14" rx="3" fill="#FF3579"/>
                <rect x="107" y="347" width="54" height="12" fill="#C6C6D2"/>
                <rect x="165" y="346" width="14" height="14" rx="3" fill="#FFDD00"/>
                <rect x="183" y="347" width="92" height="12" fill="#C6C6D2"/>
            </motion.g>
            <motion.g
                variants={widgetVariants}
                className="dougnut-chart" filter="url(#filter2_ddd_406_3176)">
                <rect x="331" y="24" width="222" height="292" rx="8" fill="#343441"/>
                <rect width="222" height="32" transform="translate(331 24)" fill="#1F1F26"/>
                <rect x="339" y="34" width="97" height="12" fill="white"/>
                <rect x="533" y="34" width="12" height="12" fill="#C6C6D2"/>
                <motion.rect                         variants={widgetLineOverlayVariant}
x="423" y="143" width="39" height="21" fill="white"/>
                <motion.rect                         variants={widgetLineOverlayVariant}
x="411" y="169" width="62" height="10" fill="#C6C6D2"/>
                <motion.path
                    variants={widgetLineVariants}
                    fill="transparent"
                    strokeWidth="10"
                    stroke="#00C0BC"
                    d="M490.79 209.79C499.122 201.459 505.181 191.132 508.391 179.795C511.6 168.458 511.853 156.488 509.125 145.025C506.398 133.563 500.78 122.99 492.807 114.314C484.835 105.638 474.774 99.1478 463.583 95.4623L460.989 103.338C470.836 106.58 479.688 112.29 486.702 119.924C493.717 127.557 498.659 136.86 501.059 146.945C503.459 157.03 503.237 167.562 500.413 177.537C497.589 187.512 492.258 196.597 484.927 203.927L490.79 209.79Z" />
                <motion.path
                    variants={widgetLineVariants}
                    transition={{delay: 5}}
                    fill="transparent"
                    strokeWidth="10"
                    stroke="#878790"
d="M393.21 209.79C399.617 216.198 407.223 221.28 415.595 224.748C423.966 228.215 432.939 230 442 230C451.061 230 460.034 228.215 468.405 224.748C476.777 221.28 484.383 216.198 490.79 209.79L484.927 203.927C479.29 209.565 472.598 214.037 465.232 217.087C457.867 220.138 449.972 221.709 442 221.709C434.028 221.709 426.133 220.138 418.768 217.087C411.402 214.037 404.71 209.565 399.073 203.927L393.21 209.79Z"/>
                <motion.path
                                        variants={widgetLineVariants}
                    fill="transparent"
                    strokeWidth="10"
                    stroke="#FF3579"
d="M373.147 156.505C373.832 146.011 376.906 135.813 382.135 126.689C387.365 117.566 394.61 109.758 403.318 103.862C412.026 97.9671 421.966 94.1402 432.379 92.674C442.793 91.2078 453.403 92.1411 463.401 95.4026L460.829 103.285C452.033 100.415 442.697 99.5943 433.536 100.884C424.374 102.174 415.628 105.541 407.966 110.728C400.305 115.915 393.93 122.785 389.329 130.812C384.728 138.839 382.023 147.812 381.42 157.045L373.147 156.505Z"/>
                <motion.path
                                        variants={widgetLineVariants}
                    fill="transparent"
                    strokeWidth="10"
                    stroke="#FFDD00"
d="M393.21 209.79C386.255 202.836 380.868 194.476 377.409 185.27C373.95 176.063 372.499 166.224 373.153 156.411L381.426 156.963C380.85 165.596 382.127 174.253 385.171 182.353C388.214 190.453 392.954 197.809 399.073 203.927L393.21 209.79Z"/>
                <rect x="343" y="254" width="14" height="14" rx="3" fill="#00C0BC"/>
                <rect x="361" y="256" width="62" height="10" fill="#C6C6D2"/>
                <rect x="517" y="255" width="24" height="12" fill="white"/>
                <rect x="343" y="272" width="14" height="14" rx="3" fill="#FF3579"/>
                <rect x="361" y="274" width="46" height="10" fill="#C6C6D2"/>
                <rect x="523" y="273" width="18" height="12" fill="white"/>
                <rect x="343" y="290" width="14" height="14" rx="3" fill="#FFDD00"/>
                <rect x="361" y="292" width="88" height="10" fill="#C6C6D2"/>
                <rect x="515" y="291" width="26" height="12" fill="white"/>
            </motion.g>
            <defs>
                <filter id="filter0_ddd_406_3176" x="0" y="92" width="477" height="320" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="8"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.04 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="6"/>
                    <feGaussianBlur stdDeviation="15"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.02 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_406_3176" result="effect2_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="16"/>
                    <feGaussianBlur stdDeviation="12"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="effect2_dropShadow_406_3176" result="effect3_dropShadow_406_3176"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_406_3176" result="shape"/>
                </filter>
                <filter id="filter1_ddd_406_3176" x="223" y="149" width="114" height="95" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="8"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.04 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="6"/>
                    <feGaussianBlur stdDeviation="15"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.02 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_406_3176" result="effect2_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="16"/>
                    <feGaussianBlur stdDeviation="12"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="effect2_dropShadow_406_3176" result="effect3_dropShadow_406_3176"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_406_3176" result="shape"/>
                </filter>
                <filter id="filter2_ddd_406_3176" x="301" y="0" width="282" height="356" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="8"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.04 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="6"/>
                    <feGaussianBlur stdDeviation="15"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.02 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_406_3176" result="effect2_dropShadow_406_3176"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="16"/>
                    <feGaussianBlur stdDeviation="12"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0068 0 0 0 0 0.0102133 0 0 0 0 0.0132 0 0 0 0.03 0"/>
                    <feBlend mode="normal" in2="effect2_dropShadow_406_3176" result="effect3_dropShadow_406_3176"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_406_3176" result="shape"/>
                </filter>
            </defs>
        </motion.svg>
    )
}