import React from 'react';
import styled from "styled-components";
import Colors from "@modbros/design-tokens/colors";
import {Breadcrumbs} from "./Breadcrumbs";

const StyledHeroHeadlineContainer = styled.div`
  background-color: ${Colors.Layer.Highlight};
  width: 100%;
  padding: 0 64px;
`

interface HeroHeadlineProps {
    children: React.ReactNode
}

export const HeroHeadline = (props: HeroHeadlineProps) => {

    const {
        children
    } = props


    return(
        <StyledHeroHeadlineContainer>
            <Breadcrumbs />
            {children}
        </StyledHeroHeadlineContainer>
    )
}