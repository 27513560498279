
import {Breadcrumbs as ImportedBreadcrumbs} from "@dynatrace/strato-components-preview";
import {Link as RouterLink, useLocation} from "react-router-dom";
import React from "react";



export const Breadcrumbs = () => {
    const location = useLocation().pathname.split('/').slice(1).map((location) => {
        return location.replace("-", ' ').charAt(0).toUpperCase() + location.slice(1);
    });

    let path = '/'

return(
<ImportedBreadcrumbs>
    <ImportedBreadcrumbs.Item as={RouterLink} to="/">Home</ImportedBreadcrumbs.Item>
    {location.map((location) => {
        path += location;
        return (
            <ImportedBreadcrumbs.Item as={RouterLink} to={path}>{location}</ImportedBreadcrumbs.Item>
        )
    })}
</ImportedBreadcrumbs>
)}
