import React from 'react';
import {DefaultPageLayout} from "./_layouts/DefaultPageLayout";
import {Features} from "../sections/Features";

export default function Pricing() {
    return (
        <DefaultPageLayout>
            <Features/>
        </DefaultPageLayout>
    );
}

