import React from 'react';
import {Flex, Container, Row, Col} from '@modbros/components/layout'
import {Heading, Text, Link as ModbrosLink} from "@modbros/components/typography";
import styled, {css} from "styled-components";
import {ArrowRightIcon} from '@modbros/icons'
import {AnimatePresence} from "framer-motion";
import {useMediaQuery} from "react-responsive";
import {PluginLayersSvg} from "../inline-svgs/PluginLayersSvg";
import {Widgets} from "../inline-svgs/Widgets";
import {Button} from "@modbros/components/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRaspberryPi} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";

type FeatureList = {
    title: string,
    text: string,
    imageUrl?: string
    imageJsx?: any,
    linkText?: string,
    link?: string,
}[]

const featureList: FeatureList = [
    {
        title: "Dashboards",
        text: "Visualize a wide range of diverse data in real-time.\n" +
            "Use existing configurations shared by other MoBro users or craft your own customized dashboard using our user-friendly dashboard builder. \n" +
            "Arrange and customize your widgets effortlessly through a simple drag-and-drop interface, mixing and matching from an ever growing range of available widget packs.",
        imageUrl: "features/mobro-dashboard-flow.gif",
        linkText: "Learn more",
        // link: "/feature/dashboard-builder"
    },
    {
        title: "Widgets",
        text: "Enhance your dashboard's capabilities and style by installing the latest widget packs from the marketplace.\n" +
            "Or unleash your creativity by creating and publishing your own widget packs using our provided SDK and documentation.",
        imageJsx: Widgets,
        linkText: "Learn more",
        // link: "/feature/widget-packs"
    },
    {
        title: "Plugins",
        text: "With MoBro, you're not limited to just tapping into your PC's hardware sensors. \n" +
            "Gather data from a diverse range of plugins installable from the marketplace — " +
            " from standard PC performance metrics to real-time weather updates or the latest Bitcoin price.\n" +
            "Or create and publish your own plugin using our provided SDK and documentation.",
        imageJsx: PluginLayersSvg,
        linkText: "Learn more",
        // link: "/feature/plugins"
    },
    {
        title: "External Displays",
        text: "Access your dashboard wherever you desire — " +
            "directly on your PC, your phone, any browser within your local network, or even on a Raspberry Pi using our customized image.",
        imageUrl: "features/mobro-app-dashboard.png",
        linkText: "Learn more",
        // link: "/feature/raspberry-pi"
    },
]

const StyledFeatureContainer = styled(Flex)`
    ${({$direction}) =>
            $direction === 'end' &&
            css`
                text-align: right;
            `
    }
}
`

export const Features = () => {

    const isAboveMobileSize = useMediaQuery({minWidth: 768})

    return (
        <Container paddingBottom={64}>
            <AnimatePresence>
                {featureList.map((feature, key) => (
                    <Row key={key} paddingTop={64} justifyContent="center">
                        <Col span={isAboveMobileSize ? 4 : 12} order={isAboveMobileSize ? key % 2 === 0 ? 0 : 1 : 0}>
                            <StyledFeatureContainer
                                height="100%"
                                flexDirection="column"
                                justifyContent="center"
                                $direction={isAboveMobileSize ? key % 2 === 0 ? 'start' : 'end' : 'start'}
                                alignItems={isAboveMobileSize ? key % 2 === 0 ? 'flex-start' : 'flex-end' : 'flex-start'}
                            >
                                <Heading level={2} headingColor="Emphasized">{feature.title}</Heading>
                                <Text>
                                    {feature.text.split('\n').map((line, index) =>
                                        [index > 0 && <br key={index}/>, line]
                                    )}
                                </Text>
                                {feature.title == 'External Displays' && (
                                    <Button prefixIcon={<FontAwesomeIcon
                                        style={{width: "20px", height: "20px", paddingTop: "1px"}}
                                        icon={faRaspberryPi}/>} as={'a'} target="_blank"
                                            href="https://www.mod-bros.com/mobro-image/download"
                                            className="link-unstyled" style={{
                                        background: '#c7053d',
                                        "--modbros-color-text-emphasized": '#fff'
                                    } as React.CSSProperties}>Download Raspberry Pi Image</Button>
                                )}
                                {/*@ts-ignore */}
                                {/*<ModbrosLink as={Link}*/}
                                {/*             to={feature.link as string}*/}
                                {/*>{feature.linkText} <ArrowRightIcon/></ModbrosLink>*/}
                            </StyledFeatureContainer>
                        </Col>
                        <Col span={isAboveMobileSize ? 5 : 12}>
                            <Flex justifyContent={key % 2 === 1 ? 'flex-start' : 'flex-end'}>
                                {feature.imageUrl && (
                                    <img className="img-fluid" src={feature.imageUrl} alt={feature.title}/>
                                )}
                                {feature.imageJsx &&
                                    <feature.imageJsx/>
                                }
                            </Flex>
                        </Col>
                    </Row>
                ))}
            </AnimatePresence>
        </Container>
    )
}