import styled from "styled-components";
import {Col, Container, Flex, Row} from "@modbros/components/layout";
import {Heading, Text} from "@modbros/components/typography";
import {Button} from "@modbros/components/form";
import { faDiscord, faPatreon } from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useMediaQuery} from "react-responsive";


const StyledPatreonSection = styled.section`
  background-color: #E5E5E5;
  background-image: url("");
  background-position: bottom right;
  
`


export const SupportUsOnPatreon = () => {

    const isAboveMobileSize = useMediaQuery({minWidth: 768})

    return(
        <StyledPatreonSection>
            <Container>
                <Row>
                    <Col span={isAboveMobileSize ? 8 : 12} alignSelf={"center"}>
                        <Flex flexDirection="column" alignItems="flex-start" gap={16} paddingTop={32}>
                            {/* @ts-ignore */}
                            <Heading style={{color: '#292933'}} level={2}>Community driven project</Heading>
                            <div>
                                <Text style={{color: '#292933'}}>We're a team of three individuals working passionately to create something remarkable!</Text>
                                <Text style={{color: '#292933'}}>Be a part of our journey by joining us on Patreon!</Text>
                            </div>
                            <Flex>
                                <Button prefixIcon={<FontAwesomeIcon style={{width: "20px", height: "20px", paddingTop: "1px"}} icon={faPatreon} />} as={'a'} target="_blank" rel="noreferrer" href="https://www.patreon.com/user?u=34439889&fan_landing=true" className="link-unstyled" style={{background: '#f96854', "--modbros-color-text-emphasized": '#fff'} as React.CSSProperties}>Join us on Patreon</Button>
                                <Button prefixIcon={<FontAwesomeIcon style={{width: "20px", height: "20px", paddingTop: "1px"}} icon={faDiscord} />} as={'a'} target="_blank" rel="noreferrer" href="https://discord.gg/DSNX4ds" className="link-unstyled" style={{background: '#7289da', "--modbros-color-text-emphasized": '#fff'} as React.CSSProperties}>Join our Discord</Button>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={isAboveMobileSize ? 4 : 12}>
                        <Flex justifyContent={isAboveMobileSize ? "flex-end" : 'center'} pb={32}>
                            <img width={320} src={"images/mobro-pc.png"} alt={"mobro community work"}/>
                        </Flex>
                    </Col>
                </Row>
            </Container>
        </StyledPatreonSection>
    )
}

