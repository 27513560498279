import React from 'react';
import {Container, Row, Col, Flex, Divider} from '@modbros/components/layout'
import { Text } from '@modbros/components/typography'
import styled from "styled-components";
import Colors from '@modbros/design-tokens/colors'
import logo from "./mobro-logo.svg";
import { ModBrosIcon } from '@modbros/icons'
import {Link} from "react-router-dom";

const StyledFooterContainer = styled.footer`
  background-color: ${Colors.Layer.Highlight}
`
const StyledBottomFooterContainer = styled.footer`
  background-color: ${Colors.Layer.Primer}
`

export const Footer = () => {


    return (
        <div>
            <StyledFooterContainer>
                <Container paddingY={32}>
                    <Row>
                        <Col>
                            <Flex flexDirection="column" alignItems="flex-start">
                                <Link to="/">
                                    <img height={33} src={logo} alt="MoBro-Logo" />
                                </Link>
                                <Flex className="link-unstyled" as={"a"} href="https://www.mod-bros.com" target="_blank" justifyContent="center" gap={4}><Text>created by</Text><ModBrosIcon/></Flex>
                                <Text textStyle="small">made with <Text as={"span"} textColor="Brand" textStyle="small">♥</Text> in Austria</Text>
                            </Flex>
                        </Col>
                        <Col>
                            {/*<Flex>*/}
                            {/*    <Flex>test</Flex>*/}
                            {/*    <Divider orientation="vertical" flexItem />*/}
                            {/*    <Flex>test</Flex>*/}
                            {/*</Flex>*/}
                        </Col>
                    </Row>
                </Container>
            </StyledFooterContainer>
            <StyledBottomFooterContainer>
                <Container paddingY={12}>
                    <Row>
                        <Col>
                            <Flex alignItems="center" height="100%">
                                <Text as={"a"} href="https://www.mod-bros.com/en/privacy" target="_blank" textStyle="small">Privacy</Text>
                                <Text as={"a"} href="https://www.mod-bros.com/en/imprint" target="_blank" textStyle="small">Imprint</Text>
                                <Text as={"a"} href="https://www.mod-bros.com/en/terms" target="_blank" textStyle="small">Terms</Text>
                            </Flex>
                        </Col>
                        <Col>
                            <Flex justifyContent="flex-end">
                                <Text>&copy; ModBros</Text>
                            </Flex>
                        </Col>
                    </Row>
                </Container>
            </StyledBottomFooterContainer>
        </div>
    )
}