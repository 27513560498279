import React from 'react';
import {DefaultPageLayout} from "./_layouts/DefaultPageLayout";
import {PriceTable} from "../sections/PriceTable";
import {TierList} from "../sections/TierList";

export default function Pricing() {
    return (
        <DefaultPageLayout>
            {/*<TierList />*/}
            <PriceTable />
        </DefaultPageLayout>
    );
}

