import React from 'react';
import {motion, Variants} from 'framer-motion'


const containerFadeInVariant: Variants = {
    offscreen: { opacity: 0 },
    onscreen: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        }
    }
}

const bounceInVariant: Variants = {
    offscreen: {
        y: 25,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.25,
            duration: 1.33
        }
    }
};

const moveInVariant: Variants = {
    offscreen: {
        x: -25,
        opacity: 0
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.25,
            duration: 1.33
        }
    }
}


export const DevicesSvg = () => {

    return (
        <motion.svg
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8}}
            variants={containerFadeInVariant}
            width="100%" height="400" viewBox="0 0 473 342" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2_2046" >
                <path d="M417.298 97.402C417.298 191.843 265.441 342 171 342C76.5593 342 0 265.441 0 171C0 76.5593 76.5593 0 171 0C265.441 0 417.298 2.96129 417.298 97.402Z" fill="#fff"/>
            </mask>
            <g mask="url(#mask0_2_2046)">
                <g filter="url(#filter0_i_2_2046)">
                    <path d="M342 171C342 265.441 265.441 342 171 342C76.5593 342 0 265.441 0 171C0 76.5593 76.5593 0 171 0C265.441 0 342 76.5593 342 171Z" fill="url(#paint0_linear_2_2046)"/>
                </g>
                <motion.g variants={moveInVariant}>
                    <path d="M-6.80115 35.9489C-6.80115 34.0708 -5.27866 32.5483 -3.40058 32.5483H354.631C356.509 32.5483 358.031 34.0708 358.031 35.9488V278.847C358.031 280.725 356.509 282.247 354.631 282.247H-3.40058C-5.27866 282.247 -6.80115 280.725 -6.80115 278.847V35.9489Z" fill="#292933"/>
                    <path d="M354.631 32.5483H-3.40058C-5.27866 32.5483 -6.80115 34.0708 -6.80115 35.9488V40.321C-6.80115 42.1991 -5.27866 43.7216 -3.40058 43.7216H358.031V35.9488C358.031 34.0708 356.509 32.5483 354.631 32.5483Z" fill="#19191F"/>
                    <rect x="-6.80115" y="43.7216" width="364.832" height="23.804" fill="url(#paint1_linear_2_2046)"/>
                    <rect x="-1.94318" y="72.8693" width="355.116" height="20.8892" fill="#343441"/>
                    <circle cx="341.028" cy="55.3807" r="8.74432" fill="white"/>
                    <rect x="179.744" y="99.1023" width="84.5284" height="108.332" fill="white"/>
                    <path d="M207.339 159.017C202.996 155.92 199.745 151.526 198.053 146.467C196.36 141.408 196.312 135.943 197.916 130.855C199.521 125.768 202.694 121.318 206.983 118.145C211.271 114.972 216.454 113.238 221.788 113.191C227.123 113.145 232.335 114.788 236.678 117.886C241.021 120.984 244.272 125.377 245.965 130.436C247.657 135.495 247.705 140.96 246.101 146.048C244.497 151.136 241.323 155.585 237.035 158.758L234.029 154.697C237.46 152.158 239.999 148.599 241.282 144.529C242.566 140.459 242.527 136.086 241.173 132.039C239.819 127.992 237.218 124.477 233.744 121.999C230.27 119.521 226.1 118.206 221.832 118.243C217.565 118.281 213.418 119.668 209.988 122.207C206.557 124.745 204.018 128.305 202.735 132.375C201.451 136.445 201.49 140.817 202.844 144.864C204.198 148.911 206.799 152.426 210.273 154.904L207.339 159.017Z" fill="#A6A8AB"/>
                    <path d="M207.339 159.017C204.475 156.975 202.07 154.356 200.276 151.33C198.483 148.303 197.341 144.936 196.925 141.443C196.508 137.95 196.826 134.408 197.857 131.045C198.889 127.682 200.611 124.571 202.915 121.912C205.218 119.253 208.051 117.105 211.233 115.604C214.414 114.103 217.874 113.284 221.391 113.198C224.908 113.112 228.404 113.761 231.655 115.105C234.906 116.448 237.841 118.456 240.272 120.999L236.619 124.489C234.675 122.455 232.327 120.849 229.726 119.774C227.125 118.699 224.328 118.18 221.515 118.249C218.701 118.317 215.933 118.973 213.388 120.174C210.843 121.374 208.576 123.093 206.733 125.22C204.891 127.347 203.513 129.836 202.688 132.526C201.863 135.217 201.608 138.05 201.942 140.845C202.275 143.639 203.188 146.333 204.623 148.754C206.057 151.175 207.982 153.27 210.273 154.904L207.339 159.017Z" fill="#97C255"/>
                    <rect x="88.9006" y="98.6165" width="84.5284" height="108.332" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M143.53 168.23L153.301 183.262L166.835 197.038L165.449 198.4L151.778 184.485L142.603 170.369L130.043 172.612L123.183 182.902H106.865L96.2264 196.614L94.6912 195.423L105.913 180.959H122.143L128.886 170.845L143.53 168.23Z" fill="#45ACE8"/>
                    <path d="M116.495 158.532C112.152 155.434 108.902 151.04 107.209 145.981C105.516 140.922 105.468 135.457 107.073 130.37C108.677 125.282 111.85 120.833 116.139 117.659C120.427 114.486 125.61 112.752 130.944 112.706C136.279 112.659 141.491 114.302 145.834 117.4C150.177 120.498 153.428 124.891 155.121 129.95C156.813 135.009 156.861 140.474 155.257 145.562C153.653 150.65 150.479 155.099 146.191 158.272L143.186 154.211C146.616 151.673 149.155 148.113 150.438 144.043C151.722 139.973 151.684 135.601 150.33 131.553C148.975 127.506 146.375 123.992 142.9 121.513C139.426 119.035 135.256 117.72 130.988 117.758C126.721 117.795 122.575 119.182 119.144 121.721C115.713 124.259 113.174 127.819 111.891 131.889C110.608 135.959 110.646 140.331 112 144.378C113.354 148.426 115.955 151.94 119.429 154.418L116.495 158.532Z" fill="#A6A8AB"/>
                    <path d="M116.495 158.532C113.631 156.489 111.226 153.87 109.432 150.844C107.639 147.818 106.498 144.45 106.081 140.957C105.665 137.464 105.982 133.922 107.014 130.559C108.045 127.196 109.768 124.085 112.071 121.426C114.374 118.767 117.207 116.619 120.389 115.118C123.571 113.618 127.031 112.798 130.547 112.712C134.064 112.626 137.56 113.276 140.811 114.619C144.062 115.962 146.997 117.97 149.428 120.513L145.775 124.004C143.831 121.969 141.483 120.363 138.882 119.288C136.281 118.214 133.484 117.694 130.671 117.763C127.857 117.832 125.089 118.487 122.544 119.688C119.999 120.888 117.732 122.607 115.89 124.734C114.047 126.861 112.669 129.35 111.844 132.041C111.019 134.731 110.765 137.564 111.098 140.359C111.431 143.153 112.344 145.847 113.779 148.268C115.214 150.689 117.138 152.784 119.429 154.418L116.495 158.532Z" fill="#97C255"/>
                    <rect x="-1.94318" y="98.6165" width="84.5284" height="108.332" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1848 168.758L29.1477 174.089L42.9136 168.833L56.0606 190.258L65.6076 187.394L75.0043 196.537L73.6492 197.929L65.0715 189.583L55.1866 192.549L42.1006 171.224L29.1477 176.169L16.3919 171.299L7.72371 197.538L5.8786 196.928L15.1848 168.758Z" fill="#45ACE8"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M229.507 173.983L237.7 185.651L247.838 182.513L255.055 191.018L253.573 192.275L247.187 184.749L236.922 187.926L229.084 176.762L208.071 192.943L198.653 183.29L190.305 197.957L188.616 196.995L198.242 180.085L208.255 190.349L229.507 173.983Z" fill="#45ACE8"/>
                    <path d="M25.6516 158.532C21.3086 155.434 18.0578 151.04 16.365 145.981C14.6723 140.922 14.6246 135.457 16.2288 130.37C17.833 125.282 21.0066 120.833 25.295 117.659C29.5833 114.486 34.7661 112.752 40.1006 112.706C45.435 112.659 50.6473 114.302 54.9903 117.4C59.3334 120.498 62.5842 124.891 64.2769 129.95C65.9697 135.009 66.0174 140.474 64.4132 145.562C62.809 150.65 59.6353 155.099 55.347 158.272L52.3418 154.211C55.7725 151.673 58.3114 148.113 59.5947 144.043C60.8781 139.973 60.8399 135.601 59.4858 131.553C58.1316 127.506 55.5309 123.992 52.0565 121.513C48.582 119.035 44.4122 117.72 40.1446 117.758C35.8771 117.795 31.7308 119.182 28.3002 121.721C24.8695 124.259 22.3306 127.819 21.0472 131.889C19.7639 135.959 19.8021 140.331 21.1562 144.378C22.5104 148.426 25.1111 151.94 28.5855 154.418L25.6516 158.532Z" fill="#A6A8AB"/>
                    <path d="M25.6516 158.532C22.7877 156.489 20.3821 153.87 18.5887 150.844C16.7954 147.818 15.6539 144.45 15.2373 140.957C14.8208 137.464 15.1384 133.922 16.1699 130.559C17.2013 127.196 18.9238 124.085 21.227 121.426C23.5303 118.767 26.3635 116.619 29.5451 115.118C32.7268 113.618 36.1869 112.798 39.7036 112.712C43.2204 112.626 46.7163 113.276 49.9675 114.619C53.2187 115.962 56.1535 117.97 58.584 120.513L54.9314 124.004C52.987 121.969 50.6392 120.363 48.0382 119.288C45.4373 118.214 42.6405 117.694 39.8271 117.763C37.0137 117.832 34.2457 118.487 31.7003 119.688C29.155 120.888 26.8884 122.607 25.0458 124.734C23.2032 126.861 21.8252 129.35 21.0001 132.041C20.1749 134.731 19.9208 137.564 20.2541 140.359C20.5873 143.153 21.5005 145.847 22.9352 148.268C24.3699 150.689 26.2944 152.784 28.5855 154.418L25.6516 158.532Z" fill="#97C255"/>
                </motion.g>
            </g>
            <motion.g variants={bounceInVariant}>
                <path d="M235.126 188.975C235.126 187.365 236.431 186.06 238.04 186.06H468.29C469.899 186.06 471.204 187.365 471.204 188.975V332.753C471.204 334.363 469.899 335.667 468.29 335.667H238.04C236.431 335.667 235.126 334.363 235.126 332.753V188.975Z" fill="#29663C"/>
                <ellipse cx="243.873" cy="330.841" rx="4.39045" ry="4.35684" fill="#EA963E"/>
                <ellipse cx="417.782" cy="330.841" rx="4.35693" ry="4.35684" fill="#EA963E"/>
                <rect x="233.182" y="184.116" width="236.079" height="149.607" fill="#808184"/>
                <rect x="238.008" y="189.01" width="226.896" height="140.357" fill="#101010"/>
                <rect x="469.26" y="189.01" width="3.4185" height="42.697" fill="#6D6E70"/>
                <rect x="469.26" y="237.538" width="3.4185" height="42.7641" fill="#6D6E70"/>
                <rect x="469.26" y="285.664" width="3.4185" height="41.7586" fill="#6D6E70"/>
                <rect x="398.343" y="333.724" width="11.194" height="3.41843" fill="#19191F"/>
                <rect x="313.819" y="333.724" width="42.7649" height="3.41843" fill="#6D6E70"/>
                <rect x="255.034" y="333.724" width="18.9694" height="3.41843" fill="#6D6E70"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M260.708 207.916L269.185 210.547L272.527 221.766H281.193L288.973 240.336L287.181 241.087L279.9 223.709H271.078L267.619 212.095L262.008 210.354L257.404 225.777L249.345 241.162L247.624 240.261L255.596 225.041L260.708 207.916ZM361.735 213.338L369.965 218.913L378.951 213.522L386.287 234.063L391.642 231.734L396.957 239.175L395.376 240.305L390.975 234.143L385.156 236.673L377.918 216.407L369.902 221.217L363.072 216.591L358.256 240.9L356.349 240.523L361.735 213.338Z" fill="#45ACE8"/>
                <path d="M305.391 250.166C300.881 246.949 297.505 242.387 295.748 237.134C293.99 231.88 293.94 226.205 295.606 220.921C297.272 215.638 300.568 211.017 305.021 207.722C309.474 204.427 314.856 202.626 320.396 202.578C325.936 202.529 331.348 204.236 335.859 207.453C340.369 210.67 343.744 215.232 345.502 220.486C347.26 225.739 347.31 231.415 345.644 236.698C343.978 241.982 340.682 246.602 336.229 249.897L331.548 243.571C334.665 241.264 336.972 238.03 338.138 234.332C339.304 230.633 339.27 226.66 338.039 222.983C336.809 219.305 334.446 216.112 331.288 213.86C328.131 211.608 324.342 210.413 320.465 210.447C316.587 210.481 312.819 211.742 309.702 214.048C306.585 216.355 304.278 219.589 303.112 223.288C301.946 226.986 301.98 230.959 303.211 234.636C304.441 238.314 306.804 241.507 309.961 243.759L305.391 250.166Z" fill="#58595B"/>
                <path d="M305.391 250.166C302.417 248.045 299.919 245.326 298.057 242.183C296.195 239.04 295.009 235.543 294.577 231.916C294.144 228.288 294.474 224.611 295.545 221.118C296.616 217.626 298.405 214.395 300.797 211.634C303.188 208.873 306.131 206.642 309.435 205.083C312.739 203.525 316.332 202.674 319.984 202.585C323.636 202.495 327.266 203.17 330.643 204.565C334.019 205.96 337.066 208.045 339.59 210.686L333.901 216.123C332.134 214.274 330.001 212.815 327.637 211.838C325.274 210.862 322.733 210.39 320.176 210.452C317.62 210.515 315.105 211.11 312.792 212.201C310.479 213.292 308.419 214.854 306.745 216.787C305.071 218.72 303.819 220.981 303.069 223.426C302.319 225.87 302.088 228.445 302.391 230.984C302.694 233.523 303.524 235.971 304.827 238.171C306.131 240.371 307.88 242.274 309.961 243.759L305.391 250.166Z" fill="#97C255"/>
                <path d="M257.298 312.348C252.788 309.131 249.412 304.569 247.654 299.315C245.896 294.062 245.847 288.387 247.512 283.103C249.178 277.82 252.474 273.199 256.927 269.904C261.381 266.609 266.763 264.808 272.302 264.76C277.842 264.711 283.255 266.418 287.765 269.635C292.275 272.852 295.651 277.414 297.409 282.668C299.166 287.921 299.216 293.596 297.55 298.88C295.884 304.163 292.588 308.784 288.135 312.079L283.454 305.753C286.571 303.446 288.878 300.212 290.044 296.513C291.211 292.815 291.176 288.842 289.945 285.165C288.715 281.487 286.352 278.294 283.195 276.042C280.038 273.79 276.249 272.595 272.371 272.629C268.493 272.663 264.726 273.924 261.608 276.23C258.491 278.537 256.184 281.771 255.018 285.47C253.852 289.168 253.887 293.141 255.117 296.818C256.348 300.496 258.711 303.689 261.868 305.941L257.298 312.348Z" fill="#58595B"/>
                <path d="M321.423 312.348C316.913 309.131 313.537 304.569 311.779 299.315C310.021 294.062 309.972 288.387 311.637 283.103C313.303 277.82 316.599 273.199 321.052 269.904C325.506 266.609 330.888 264.808 336.427 264.76C341.967 264.711 347.38 266.418 351.89 269.635C356.4 272.852 359.776 277.414 361.534 282.668C363.291 287.921 363.341 293.596 361.675 298.88C360.009 304.163 356.713 308.784 352.26 312.079L347.579 305.753C350.696 303.446 353.003 300.212 354.169 296.513C355.336 292.815 355.301 288.842 354.07 285.165C352.84 281.487 350.477 278.294 347.32 276.042C344.163 273.79 340.374 272.595 336.496 272.629C332.618 272.663 328.851 273.924 325.733 276.23C322.616 278.537 320.309 281.771 319.143 285.47C317.977 289.168 318.012 293.141 319.242 296.818C320.473 300.496 322.836 303.689 325.993 305.941L321.423 312.348Z" fill="#58595B"/>
                <path d="M257.298 312.348C251.634 308.308 247.806 302.183 246.658 295.321C245.51 288.459 247.134 281.422 251.175 275.758C255.215 270.094 261.34 266.267 268.202 265.118C275.064 263.97 282.101 265.595 287.765 269.635L283.195 276.042C279.23 273.214 274.304 272.076 269.5 272.88C264.697 273.684 260.41 276.363 257.582 280.328C254.753 284.293 253.616 289.219 254.42 294.022C255.224 298.826 257.903 303.113 261.868 305.941L257.298 312.348Z" fill="#97C255"/>
                <path d="M321.423 312.348C318.04 309.935 315.278 306.753 313.366 303.064C311.454 299.375 310.445 295.284 310.424 291.129C310.402 286.973 311.368 282.872 313.241 279.163C315.115 275.454 317.843 272.243 321.2 269.795L325.837 276.154C323.487 277.868 321.577 280.115 320.266 282.712C318.954 285.308 318.278 288.179 318.293 291.087C318.309 293.996 319.015 296.86 320.353 299.442C321.692 302.025 323.625 304.252 325.993 305.941L321.423 312.348Z" fill="#97C255"/>
                <path d="M413.724 250.166C409.214 246.949 405.838 242.387 404.08 237.134C402.322 231.88 402.273 226.205 403.939 220.921C405.604 215.638 408.9 211.017 413.353 207.722C417.807 204.427 423.189 202.626 428.728 202.578C434.268 202.529 439.681 204.236 444.191 207.453C448.701 210.67 452.077 215.232 453.835 220.486C455.593 225.739 455.642 231.415 453.976 236.698C452.31 241.982 449.015 246.602 444.561 249.897L439.88 243.571C442.997 241.264 445.304 238.03 446.471 234.332C447.637 230.633 447.602 226.66 446.372 222.983C445.141 219.305 442.778 216.112 439.621 213.86C436.464 211.608 432.675 210.413 428.797 210.447C424.919 210.481 421.152 211.742 418.035 214.048C414.917 216.355 412.61 219.589 411.444 223.288C410.278 226.986 410.313 230.959 411.543 234.636C412.774 238.314 415.137 241.507 418.294 243.759L413.724 250.166Z" fill="#58595B"/>
                <path d="M413.724 250.166C409.943 247.47 406.946 243.818 405.039 239.584C403.132 235.35 402.383 230.686 402.868 226.068L410.695 226.89C410.355 230.123 410.88 233.388 412.215 236.352C413.55 239.316 415.648 241.872 418.294 243.759L413.724 250.166Z" fill="#97C255"/>
                <path d="M411.954 290.02C411.954 301.02 403.037 309.938 392.037 309.938C381.037 309.938 372.119 301.02 372.119 290.02C372.119 279.02 381.037 270.102 392.037 270.102C403.037 270.102 411.954 279.02 411.954 290.02ZM377.099 290.02C377.099 298.27 383.787 304.958 392.037 304.958C400.287 304.958 406.975 298.27 406.975 290.02C406.975 281.77 400.287 275.082 392.037 275.082C383.787 275.082 377.099 281.77 377.099 290.02Z" fill="#58595B"/>
                <path d="M392.037 270.102C395.807 270.102 399.499 271.172 402.685 273.188C405.871 275.203 408.42 278.081 410.035 281.488C411.649 284.894 412.264 288.689 411.808 292.431C411.352 296.173 409.842 299.709 407.456 302.628C405.07 305.546 401.904 307.728 398.327 308.918C394.75 310.109 390.909 310.26 387.25 309.354C383.59 308.448 380.263 306.521 377.655 303.799C375.047 301.077 373.265 297.671 372.516 293.976L377.396 292.987C377.958 295.758 379.294 298.313 381.25 300.354C383.207 302.396 385.702 303.841 388.446 304.52C391.191 305.2 394.072 305.087 396.755 304.194C399.437 303.301 401.812 301.665 403.601 299.476C405.391 297.287 406.523 294.635 406.865 291.828C407.208 289.022 406.746 286.176 405.535 283.621C404.324 281.066 402.413 278.907 400.023 277.396C397.634 275.884 394.864 275.082 392.037 275.082V270.102Z" fill="#62E0C5"/>
                <path d="M457.619 290.02C457.619 301.02 448.702 309.938 437.702 309.938C426.701 309.938 417.784 301.02 417.784 290.02C417.784 279.02 426.701 270.102 437.702 270.102C448.702 270.102 457.619 279.02 457.619 290.02ZM422.763 290.02C422.763 298.27 429.452 304.958 437.702 304.958C445.952 304.958 452.64 298.27 452.64 290.02C452.64 281.77 445.952 275.082 437.702 275.082C429.452 275.082 422.763 281.77 422.763 290.02Z" fill="#58595B"/>
                <path d="M437.702 270.102C441.765 270.102 445.731 271.345 449.067 273.663C452.404 275.982 454.951 279.265 456.369 283.073C457.786 286.881 458.004 291.031 456.995 294.967C455.986 298.903 453.797 302.436 450.723 305.092L447.468 301.324C449.773 299.332 451.415 296.682 452.172 293.73C452.929 290.778 452.765 287.666 451.702 284.81C450.639 281.954 448.728 279.491 446.226 277.752C443.723 276.014 440.749 275.082 437.702 275.082V270.102Z" fill="#62E0C5"/>
            </motion.g>
            <defs>
                <filter id="filter0_i_2_2046" x="0" y="0" width="353" height="342" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="11"/>
                    <feGaussianBlur stdDeviation="6"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_2046"/>
                </filter>
                <linearGradient id="paint0_linear_2_2046" x1="-7.52983" y1="106.146" x2="234.396" y2="327.183" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF3300"/>
                    <stop offset="1" stop-color="#BB0040"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2_2046" x1="-6.80115" y1="55.3806" x2="358.031" y2="55.3807" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C16261"/>
                    <stop offset="1" stop-color="#C16261"/>
                </linearGradient>
            </defs>
        </motion.svg>
    )
}