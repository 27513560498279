/**
 * @license
 * Copyright 2023 Dynatrace LLC
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Generated file, do not edit directly!
 */

const customPropertiesUnified = {
    /** Use as the default border style. */
    '--dt-borders-style-default': 'solid',
    /** Use to indicate interactive text. */
    '--dt-borders-style-dashed': 'dashed',
    /** Use as the default border width. */
    '--dt-borders-width-default': '1px',
    /** Use to emphasize borders. This can be useful to clearly separate content. */
    '--dt-borders-width-emphasized': '2px',
    /** Use as the default border radius for surfaces. */
    '--dt-borders-radius-surface-default': '16px',
    /** Use as the subdued border radius for containers. */
    '--dt-borders-radius-container-subdued': '8px',
    /** Use as the default border radius for containers. */
    '--dt-borders-radius-container-default': '12px',
    /** Use as the subdued border radius for fields. */
    '--dt-borders-radius-field-subdued': '4px',
    /** Use as the default border radius for fields. */
    '--dt-borders-radius-field-default': '8px',
    /** Use for rounded or pill-like shapes. */
    '--dt-borders-radius-field-emphasized': '9999px',
    /** Use as the default level of elevation. */
    '--dt-box-shadows-surface-flat-rest': 'none',
    /** Use as the default level of elevation when a user's pointer is over an interactive surface. */
    '--dt-box-shadows-surface-flat-hover':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 4px 8px #2f2f3bcc',
    /** Use as the default level of elevation when the user is pressing an interactive surface. */
    '--dt-box-shadows-surface-flat-active': 'none',
    /** Use as the default level of elevation when moving a surface, on drag-and-drop interaction. */
    '--dt-box-shadows-surface-flat-drag':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 13px 8px #2f2f3bcc',
    /** Use for raised surfaces. */
    '--dt-box-shadows-surface-raised-rest':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 4px 8px #2f2f3bcc',
    /** Use when a user's pointer is over an interactive raised surface. */
    '--dt-box-shadows-surface-raised-hover':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 7px 8px #2f2f3bcc',
    /** Use when the user is pressing an interactive raised surface. */
    '--dt-box-shadows-surface-raised-active':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 4px 8px #2f2f3bcc',
    /** Use when moving a raised surface, on drag-and-drop interaction. */
    '--dt-box-shadows-surface-raised-drag':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 13px 8px #2f2f3bcc',
    /** Use for floating surfaces. */
    '--dt-box-shadows-surface-floating-rest':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 7px 8px #2f2f3bcc',
    /** Use when a user's pointer is over an interactive floating surface. */
    '--dt-box-shadows-surface-floating-hover':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 10px 8px #2f2f3bcc',
    /** Use when the user is pressing an interactive floating surface. */
    '--dt-box-shadows-surface-floating-active':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 7px 8px #2f2f3bcc',
    /** Use when moving a floating surface, on drag-and-drop interaction. */
    '--dt-box-shadows-surface-floating-drag':
        '0px 0px 0px 1px #ffffff1f, 0px 1px 2px #2f2f3b, 0px 13px 8px #2f2f3bcc',
    /** Use to constrain screen sizes that are 640px wide or less. */
    '--dt-breakpoints-max-mobile': '640px',
    /** Use to constrain screen sizes that are 641px wide or more. */
    '--dt-breakpoints-max-tablet': '960px',
    /** Use to constrain screen sizes that are 960px wide or less. */
    '--dt-breakpoints-max-desktop': '1920px',
    /** Use to constrain screen sizes that are 961px wide or more. */
    '--dt-breakpoints-min-tablet': '641px',
    /** Use to constrain screen sizes that are 1920 wide or less. */
    '--dt-breakpoints-min-desktop': '961px',
    /** Use to constrain screen sizes that are 1921px wide or more. */
    '--dt-breakpoints-min-widescreen': '1921px',
    '--dt-colors-theme-foreground-10': '#fff',
    '--dt-colors-theme-foreground-20': '#2f2f3b',
    '--dt-colors-theme-background-10': '#343441',
    '--dt-colors-theme-background-20': '#1f1f26',
    '--dt-colors-theme-neutral-10': '#3a3a48',
    '--dt-colors-theme-neutral-20': '#41414f',
    '--dt-colors-theme-neutral-30': '#474755',
    '--dt-colors-theme-neutral-40': '#4d4d5c',
    '--dt-colors-theme-neutral-50': '#545463',
    '--dt-colors-theme-neutral-60': '#676777',
    '--dt-colors-theme-neutral-70': '#babac6',
    '--dt-colors-theme-neutral-80': '#d7d7dd',
    '--dt-colors-theme-neutral-90': '#ebebee',
    '--dt-colors-theme-primary-10': '#313140',
    '--dt-colors-theme-primary-20': '#2e2f3e',
    '--dt-colors-theme-primary-30': '#2b2c3d',
    '--dt-colors-theme-primary-40': '#29293b',
    '--dt-colors-theme-primary-50': '#26263a',
    '--dt-colors-theme-primary-60': '#1d1e36',
    '--dt-colors-theme-primary-70': '#9093d8',
    '--dt-colors-theme-primary-80': '#adafe3',
    '--dt-colors-theme-primary-90': '#c2c4ea',
    '--dt-colors-theme-success-10': '#303d44',
    '--dt-colors-theme-success-20': '#2d4646',
    '--dt-colors-theme-success-30': '#294f49',
    '--dt-colors-theme-success-40': '#25584b',
    '--dt-colors-theme-success-50': '#22614e',
    '--dt-colors-theme-success-60': '#177c55',
    '--dt-colors-theme-success-70': '#00d779',
    '--dt-colors-theme-success-80': '#00f68c',
    '--dt-colors-theme-success-90': '#a1ffc2',
    '--dt-colors-theme-warning-10': '#40393c',
    '--dt-colors-theme-warning-20': '#4b3e38',
    '--dt-colors-theme-warning-30': '#574233',
    '--dt-colors-theme-warning-40': '#62472f',
    '--dt-colors-theme-warning-50': '#6e4c2a',
    '--dt-colors-theme-warning-60': '#905a1d',
    '--dt-colors-theme-warning-70': '#ffa365',
    '--dt-colors-theme-warning-80': '#ffc9af',
    '--dt-colors-theme-warning-90': '#ffe2d6',
    '--dt-colors-theme-critical-10': '#3e303d',
    '--dt-colors-theme-critical-20': '#482d38',
    '--dt-colors-theme-critical-30': '#512934',
    '--dt-colors-theme-critical-40': '#5b2530',
    '--dt-colors-theme-critical-50': '#65222c',
    '--dt-colors-theme-critical-60': '#82171f',
    '--dt-colors-theme-critical-70': '#ff7a7b',
    '--dt-colors-theme-critical-80': '#ffa7a7',
    '--dt-colors-theme-critical-90': '#ffc3c3',
    /** Use for the background of your app. */
    '--dt-colors-background-base-default': '#343441',
    /** Use as the backdrop for surfaces to reduce the visual noise of background elements. */
    '--dt-colors-background-surface-backdrop': '#343441cc',
    /** Use for default surface background. */
    '--dt-colors-background-surface-default': '#1f1f26',
    /** Use for subtle containers. */
    '--dt-colors-background-container-neutral-subdued': '#343441',
    /** Use for default containers. */
    '--dt-colors-background-container-neutral-default': '#3a3a48',
    /** Use for emphasized neutral containers that draw attention. */
    '--dt-colors-background-container-neutral-emphasized': '#41414f',
    /** Use for accentuated neutral containers with high contrast. Use sparingly. */
    '--dt-colors-background-container-neutral-accent': '#babac6',
    /** Use for default primary color containers. */
    '--dt-colors-background-container-primary-default': '#313140',
    /** Use for emphasized primary color containers that draw attention. */
    '--dt-colors-background-container-primary-emphasized': '#2e2f3e',
    /** Use for accentuated primary color containers with high contrast. Use sparingly. */
    '--dt-colors-background-container-primary-accent': '#9093d8',
    /** Use for default success color containers. */
    '--dt-colors-background-container-success-default': '#303d44',
    /** Use for emphasized success color containers that draw attention. */
    '--dt-colors-background-container-success-emphasized': '#2d4646',
    /** Use for accentuated success color containers with high contrast. Use sparingly. */
    '--dt-colors-background-container-success-accent': '#00d779',
    /** Use for default warning color containers. */
    '--dt-colors-background-container-warning-default': '#40393c',
    /** Use for emphasized warning color containers that draw attention. */
    '--dt-colors-background-container-warning-emphasized': '#4b3e38',
    /** Use for accentuated warning color containers with high contrast. Use sparingly. */
    '--dt-colors-background-container-warning-accent': '#ffa365',
    /** Use for default critical color containers. */
    '--dt-colors-background-container-critical-default': '#3e303d',
    /** Use for emphasized critical color containers that draw attention. */
    '--dt-colors-background-container-critical-emphasized': '#482d38',
    /** Use for accentuated critical color containers with high contrast. Use sparingly. */
    '--dt-colors-background-container-critical-accent': '#ff7a7b',
    /** Use for disabled neutral fields. */
    '--dt-colors-background-field-neutral-disabled': '#47475580',
    /** Use for default neutral fields. */
    '--dt-colors-background-field-neutral-default': '#47475500',
    /** Use for hover interaction on default neutral fields. */
    '--dt-colors-background-field-neutral-default-hover': '#4d4d5c',
    /** Use for click interaction on default neutral fields. */
    '--dt-colors-background-field-neutral-default-active': '#545463',
    /** Use for emphasized neutral fields. */
    '--dt-colors-background-field-neutral-emphasized': '#474755',
    /** Use for hover interaction on emphasized neutral fields. */
    '--dt-colors-background-field-neutral-emphasized-hover': '#4d4d5c',
    /** Use for click interaction on emphasized neutral fields. */
    '--dt-colors-background-field-neutral-emphasized-active': '#545463',
    /** Use for accentuated neutral fields. Use sparingly. */
    '--dt-colors-background-field-neutral-accent': '#babac6',
    /** Use for hover interaction on accentuated neutral fields. */
    '--dt-colors-background-field-neutral-accent-hover': '#d7d7dd',
    /** Use for click interaction on accentuated neutral fields. */
    '--dt-colors-background-field-neutral-accent-active': '#ebebee',
    /** Use for disabled primary fields. */
    '--dt-colors-background-field-primary-disabled': '#47475580',
    /** Use for default primary fields. */
    '--dt-colors-background-field-primary-default': '#2b2c3d00',
    /** Use for hover interaction on default primary fields. */
    '--dt-colors-background-field-primary-default-hover': '#29293b',
    /** Use for click interaction on default primary fields. */
    '--dt-colors-background-field-primary-default-active': '#26263a',
    /** Use for emphasized primary fields. */
    '--dt-colors-background-field-primary-emphasized': '#2b2c3d',
    /** Use for hover interaction on emphasized primary fields. */
    '--dt-colors-background-field-primary-emphasized-hover': '#29293b',
    /** Use for click interaction on emphasized primary fields. */
    '--dt-colors-background-field-primary-emphasized-active': '#26263a',
    /** Use for accentuated primary fields. Use sparingly. */
    '--dt-colors-background-field-primary-accent': '#9093d8',
    /** Use for hover interaction on accentuated primary fields. */
    '--dt-colors-background-field-primary-accent-hover': '#adafe3',
    /** Use for click interaction on accentuated primary fields. */
    '--dt-colors-background-field-primary-accent-active': '#c2c4ea',
    /** Use for disabled success fields. */
    '--dt-colors-background-field-success-disabled': '#47475580',
    /** Use for default success fields. */
    '--dt-colors-background-field-success-default': '#294f4900',
    /** Use for hover interaction on default success fields. */
    '--dt-colors-background-field-success-default-hover': '#25584b',
    /** Use for click interaction on default success fields. */
    '--dt-colors-background-field-success-default-active': '#22614e',
    /** Use for emphasized success fields. */
    '--dt-colors-background-field-success-emphasized': '#294f49',
    /** Use for hover interaction on emphasized success fields. */
    '--dt-colors-background-field-success-emphasized-hover': '#25584b',
    /** Use for click interaction on emphasized success fields. */
    '--dt-colors-background-field-success-emphasized-active': '#22614e',
    /** Use for accentuated success fields. Use sparingly. */
    '--dt-colors-background-field-success-accent': '#00d779',
    /** Use for hover interaction on accentuated success fields. */
    '--dt-colors-background-field-success-accent-hover': '#00f68c',
    /** Use for click interaction on accentuated success fields. */
    '--dt-colors-background-field-success-accent-active': '#a1ffc2',
    /** Use for disabled warning fields. */
    '--dt-colors-background-field-warning-disabled': '#47475580',
    /** Use for default warning fields. */
    '--dt-colors-background-field-warning-default': '#57423300',
    /** Use for hover interaction on default warning fields. */
    '--dt-colors-background-field-warning-default-hover': '#62472f',
    /** Use for click interaction on default warning fields. */
    '--dt-colors-background-field-warning-default-active': '#6e4c2a',
    /** Use for emphasized warning fields. */
    '--dt-colors-background-field-warning-emphasized': '#574233',
    /** Use for hover interaction on emphasized warning fields. */
    '--dt-colors-background-field-warning-emphasized-hover': '#62472f',
    /** Use for click interaction on emphasized warning fields. */
    '--dt-colors-background-field-warning-emphasized-active': '#6e4c2a',
    /** Use for accentuated warning fields. Use sparingly. */
    '--dt-colors-background-field-warning-accent': '#ffa365',
    /** Use for hover interaction on accentuated warning fields. */
    '--dt-colors-background-field-warning-accent-hover': '#ffc9af',
    /** Use for click interaction on accentuated warning fields. */
    '--dt-colors-background-field-warning-accent-active': '#ffe2d6',
    /** Use for disabled neutral fields. */
    '--dt-colors-background-field-critical-disabled': '#47475580',
    /** Use for default neutral fields. */
    '--dt-colors-background-field-critical-default': '#51293400',
    /** Use for hover interaction on default neutral fields. */
    '--dt-colors-background-field-critical-default-hover': '#5b2530',
    /** Use for click interaction on default neutral fields. */
    '--dt-colors-background-field-critical-default-active': '#65222c',
    /** Use for emphasized neutral fields. */
    '--dt-colors-background-field-critical-emphasized': '#512934',
    /** Use for hover interaction on emphasized neutral fields. */
    '--dt-colors-background-field-critical-emphasized-hover': '#5b2530',
    /** Use for click interaction on emphasized neutral fields. */
    '--dt-colors-background-field-critical-emphasized-active': '#65222c',
    /** Use for accentuated neutral fields. Use sparingly. */
    '--dt-colors-background-field-critical-accent': '#ff7a7b',
    /** Use for hover interaction on accentuated neutral fields. */
    '--dt-colors-background-field-critical-accent-hover': '#ffa7a7',
    /** Use for click interaction on accentuated neutral fields. */
    '--dt-colors-background-field-critical-accent-active': '#ffc3c3',
    /** Use for disabled fields placed on accentuated background. */
    '--dt-colors-background-field-on-accent-disabled': '#3434411a',
    /** Use for default fields placed on accentuated background. */
    '--dt-colors-background-field-on-accent-default': '#34344100',
    /** Use for hover interaction on default fields on an accentuated background. */
    '--dt-colors-background-field-on-accent-default-hover': '#3434414d',
    /** Use for click interaction on default fields on an accentuated background. */
    '--dt-colors-background-field-on-accent-default-active': '#34344166',
    /** Use for emphasized fields placed on accentuated background. */
    '--dt-colors-background-field-on-accent-emphasized': '#34344133',
    /** Use for hover interaction on emphasized fields on an accentuated background. */
    '--dt-colors-background-field-on-accent-emphasized-hover': '#3434414d',
    /** Use for click interaction on emphasized fields on an accentuated background. */
    '--dt-colors-background-field-on-accent-emphasized-active': '#34344166',
    /** Use as the default border color in a disabled state. */
    '--dt-colors-border-neutral-disabled': '#4d4d5c80',
    /** Use as the default border color. */
    '--dt-colors-border-neutral-default': '#4d4d5c',
    /** Use as default border color on hover interaction. */
    '--dt-colors-border-neutral-default-hover': '#676777',
    /** Use as default border color on click interaction. */
    '--dt-colors-border-neutral-default-active': '#babac6',
    /** Use as the border color of accentuated elements. */
    '--dt-colors-border-neutral-accent': '#babac6',
    /** Use as border color of accentuated elements on hover interaction. */
    '--dt-colors-border-neutral-accent-hover': '#d7d7dd',
    /** Use as border color of accentuated elements on click interaction. */
    '--dt-colors-border-neutral-accent-active': '#ebebee',
    /** Use as border primary color in a disabled state. */
    '--dt-colors-border-primary-disabled': '#4d4d5c80',
    /** Use as the default primary border color. */
    '--dt-colors-border-primary-default': '#29293b',
    /** Use as default border primary color on hover interaction. */
    '--dt-colors-border-primary-default-hover': '#1d1e36',
    /** Use as default border primary color on click interaction. */
    '--dt-colors-border-primary-default-active': '#9093d8',
    /** Use as the border color of primary accentuated elements. */
    '--dt-colors-border-primary-accent': '#9093d8',
    /** Use as border color of primary accentuated elements on hover interaction. */
    '--dt-colors-border-primary-accent-hover': '#adafe3',
    /** Use as border color of primary accentuated elements on click interaction. */
    '--dt-colors-border-primary-accent-active': '#c2c4ea',
    /** Use as border success color in a disabled state. */
    '--dt-colors-border-success-disabled': '#4d4d5c80',
    /** Use as the default success border color. */
    '--dt-colors-border-success-default': '#25584b',
    /** Use as default border success color on hover interaction. */
    '--dt-colors-border-success-default-hover': '#177c55',
    /** Use as default border success color on click interaction. */
    '--dt-colors-border-success-default-active': '#00d779',
    /** Use as the border color of success accentuated elements. */
    '--dt-colors-border-success-accent': '#00d779',
    /** Use as border color of success accentuated elements on hover interaction. */
    '--dt-colors-border-success-accent-hover': '#00f68c',
    /** Use as border color of success accentuated elements on click interaction. */
    '--dt-colors-border-success-accent-active': '#a1ffc2',
    /** Use as border warning color in a disabled state. */
    '--dt-colors-border-warning-disabled': '#4d4d5c80',
    /** Use as the default warning border color. */
    '--dt-colors-border-warning-default': '#62472f',
    /** Use as default border warning color on hover interaction. */
    '--dt-colors-border-warning-default-hover': '#905a1d',
    /** Use as default border warning color on click interaction. */
    '--dt-colors-border-warning-default-active': '#ffa365',
    /** Use as the border color of warning accentuated elements. */
    '--dt-colors-border-warning-accent': '#ffa365',
    /** Use as border color of warning accentuated elements on hover interaction. */
    '--dt-colors-border-warning-accent-hover': '#ffc9af',
    /** Use as border color of warning accentuated elements on click interaction. */
    '--dt-colors-border-warning-accent-active': '#ffe2d6',
    /** Use as border critical color in a disabled state. */
    '--dt-colors-border-critical-disabled': '#4d4d5c80',
    /** Use as the default critical border color. */
    '--dt-colors-border-critical-default': '#5b2530',
    /** Use as default border critical color on hover interaction. */
    '--dt-colors-border-critical-default-hover': '#82171f',
    /** Use as default border critical color on click interaction. */
    '--dt-colors-border-critical-default-active': '#ff7a7b',
    /** Use as the border color of critical accentuated elements. */
    '--dt-colors-border-critical-accent': '#ff7a7b',
    /** Use as border color of critical accentuated elements on hover interaction. */
    '--dt-colors-border-critical-accent-hover': '#ffa7a7',
    /** Use as border color of critical accentuated elements on click interaction. */
    '--dt-colors-border-critical-accent-active': '#ffc3c3',
    /** Use for border color of elements on an accentuated background in a disabled state. */
    '--dt-colors-border-on-accent-disabled': '#34344133',
    /** Use as the default border color of elements on an accentuated background. */
    '--dt-colors-border-on-accent-default': '#343441',
    /** Use as border color on hover interaction of elements on an accentuated background. */
    '--dt-colors-border-on-accent-default-hover': '#343441cc',
    /** Use as border color on click interaction of elements on an accentuated background. */
    '--dt-colors-border-on-accent-default-active': '#34344199',
    /** Use for text color on disabled state. */
    '--dt-colors-text-neutral-disabled': '#babac680',
    /** Use for subtle, understated text color. */
    '--dt-colors-text-neutral-subdued': '#babac6',
    /** Use for subtle, understated text color on hover interaction. */
    '--dt-colors-text-neutral-subdued-hover': '#d7d7dd',
    /** Use for subtle, understated text color on click interaction. */
    '--dt-colors-text-neutral-subdued-active': '#ebebee',
    /** Use as the default text color. */
    '--dt-colors-text-neutral-default': '#fff',
    /** Use as the default text color on hover interaction. */
    '--dt-colors-text-neutral-default-hover': '#ebebee',
    /** Use as the default text color on click interaction. */
    '--dt-colors-text-neutral-default-active': '#d7d7dd',
    /** Use for primary text color on disabled state. */
    '--dt-colors-text-primary-disabled': '#babac680',
    /** Use as the default primary text color. */
    '--dt-colors-text-primary-default': '#9093d8',
    /** Use as the default primary text color on hover interaction. */
    '--dt-colors-text-primary-default-hover': '#adafe3',
    /** Use as the default primary text color on click interaction. */
    '--dt-colors-text-primary-default-active': '#c2c4ea',
    /** Use for success text color on disabled state. */
    '--dt-colors-text-success-disabled': '#babac680',
    /** Use as the default success text color. */
    '--dt-colors-text-success-default': '#00d779',
    /** Use as the default success text color on hover interaction. */
    '--dt-colors-text-success-default-hover': '#00f68c',
    /** Use as the default success text color on click interaction. */
    '--dt-colors-text-success-default-active': '#a1ffc2',
    /** Use for warning text color on disabled state. */
    '--dt-colors-text-warning-disabled': '#babac680',
    /** Use as the default warning text color. */
    '--dt-colors-text-warning-default': '#ffa365',
    /** Use as the default warning text color on hover interaction. */
    '--dt-colors-text-warning-default-hover': '#ffc9af',
    /** Use as the default warning text color on click interaction. */
    '--dt-colors-text-warning-default-active': '#ffe2d6',
    /** Use for critical text color on disabled state. */
    '--dt-colors-text-critical-disabled': '#babac680',
    /** Use as the default critical text color. */
    '--dt-colors-text-critical-default': '#ff7a7b',
    /** Use as the default critical text color on hover interaction. */
    '--dt-colors-text-critical-default-hover': '#ffa7a7',
    /** Use as the default critical text color on click interaction. */
    '--dt-colors-text-critical-default-active': '#ffc3c3',
    /** Use for text color placed on accentuated background on disabled state. */
    '--dt-colors-text-on-accent-disabled': '#34344180',
    /** Use for text color placed on accentuated background. */
    '--dt-colors-text-on-accent-default': '#343441',
    /** Use for text color placed on accentuated background on hover interaction. */
    '--dt-colors-text-on-accent-default-hover': '#343441cc',
    /** Use for text color placed on accentuated background on click interaction. */
    '--dt-colors-text-on-accent-default-active': '#34344199',
    /** Use for icon color on disabled state. */
    '--dt-colors-icon-neutral-disabled': '#babac680',
    /** Use for subtle, understated icon color. */
    '--dt-colors-icon-neutral-subdued': '#babac6',
    /** Use for subtle, understated icon color on hover interaction. */
    '--dt-colors-icon-neutral-subdued-hover': '#d7d7dd',
    /** Use for subtle, understated icon color on click interaction. */
    '--dt-colors-icon-neutral-subdued-active': '#ebebee',
    /** Use as the default icon color. */
    '--dt-colors-icon-neutral-default': '#fff',
    /** Use as the default icon color on hover interaction. */
    '--dt-colors-icon-neutral-default-hover': '#ebebee',
    /** Use as the default icon color on click interaction. */
    '--dt-colors-icon-neutral-default-active': '#d7d7dd',
    /** Use for primary icon color on disabled state. */
    '--dt-colors-icon-primary-disabled': '#babac680',
    /** Use as the default primary icon color. */
    '--dt-colors-icon-primary-default': '#9093d8',
    /** Use as the default primary icon color on hover interaction. */
    '--dt-colors-icon-primary-default-hover': '#adafe3',
    /** Use as the default primary icon color on click interaction. */
    '--dt-colors-icon-primary-default-active': '#c2c4ea',
    /** Use for success icon color on disabled state. */
    '--dt-colors-icon-success-disabled': '#babac680',
    /** Use as the default success icon color. */
    '--dt-colors-icon-success-default': '#00d779',
    /** Use as the default success icon color on hover interaction. */
    '--dt-colors-icon-success-default-hover': '#00f68c',
    /** Use as the default success icon color on click interaction. */
    '--dt-colors-icon-success-default-active': '#a1ffc2',
    /** Use for warning icon color on disabled state. */
    '--dt-colors-icon-warning-disabled': '#babac680',
    /** Use as the default warning icon color. */
    '--dt-colors-icon-warning-default': '#ffa365',
    /** Use as the default warning icon color on hover interaction. */
    '--dt-colors-icon-warning-default-hover': '#ffc9af',
    /** Use as the default warning icon color on click interaction. */
    '--dt-colors-icon-warning-default-active': '#ffe2d6',
    /** Use for critical icon color on disabled state. */
    '--dt-colors-icon-critical-disabled': '#babac680',
    /** Use as the default critical icon color. */
    '--dt-colors-icon-critical-default': '#ff7a7b',
    /** Use as the default critical icon color on hover interaction. */
    '--dt-colors-icon-critical-default-hover': '#ffa7a7',
    /** Use as the default critical icon color on click interaction. */
    '--dt-colors-icon-critical-default-active': '#ffc3c3',
    /** Use for icon color placed on accentuated background on disabled state. */
    '--dt-colors-icon-on-accent-disabled': '#34344180',
    /** Use for icon color placed on accentuated background. */
    '--dt-colors-icon-on-accent-default': '#343441',
    /** Use for icon color placed on accentuated background on hover interaction. */
    '--dt-colors-icon-on-accent-default-hover': '#343441cc',
    /** Use for icon color placed on accentuated background on click interaction. */
    '--dt-colors-icon-on-accent-default-active': '#34344199',
    /** Use to style 'Comment' syntax on a code editor context. */
    '--dt-colors-syntax-comment': '#647878',
    /** Use to style 'String' syntax on a code editor context. */
    '--dt-colors-syntax-string': '#acdb66',
    /** Use to style 'Variable' syntax on a code editor context. */
    '--dt-colors-syntax-variable': '#d6deeb',
    /** Use to style 'Number' syntax on a code editor context. */
    '--dt-colors-syntax-number': '#f78e6e',
    /** Use to style 'Punctuation' syntax on a code editor context. */
    '--dt-colors-syntax-punctuation': '#80a8ff',
    /** Use to style 'Function' syntax on a code editor context. */
    '--dt-colors-syntax-function': '#80a8ff',
    /** Use to style 'Classname' syntax on a code editor context. */
    '--dt-colors-syntax-classname': '#ffca8a',
    /** Use to style 'Tag' syntax on a code editor context. */
    '--dt-colors-syntax-tag': '#80dbca',
    /** Use to style 'Operators' syntax on a code editor context. */
    '--dt-colors-syntax-operators': '#80dbca',
    /** Use to style 'Boolean' syntax on a code editor context. */
    '--dt-colors-syntax-boolean': '#ff5773',
    '--dt-colors-charts-categorical-color-01-default': '#7c7cdf',
    '--dt-colors-charts-categorical-color-01-hover': '#9595e4',
    '--dt-colors-charts-categorical-color-01-active': '#b1b1ec',
    '--dt-colors-charts-categorical-color-01-default-transparent': '#8e8effcc',
    '--dt-colors-charts-categorical-color-01-hover-transparent': '#adadffcc',
    '--dt-colors-charts-categorical-color-01-active-transparent': '#d2d1ffcc',
    '--dt-colors-charts-categorical-color-02-default': '#e5e9ff',
    '--dt-colors-charts-categorical-color-02-hover': '#cdd1e5',
    '--dt-colors-charts-categorical-color-02-active': '#b7bbcc',
    '--dt-colors-charts-categorical-color-02-default-transparent': '#fffc',
    '--dt-colors-charts-categorical-color-02-hover-transparent': '#f5f8ffcc',
    '--dt-colors-charts-categorical-color-02-active-transparent': '#d8dcefcc',
    '--dt-colors-charts-categorical-color-03-default': '#00c2ab',
    '--dt-colors-charts-categorical-color-03-hover': '#4ed4c5',
    '--dt-colors-charts-categorical-color-03-active': '#7fe1d6',
    '--dt-colors-charts-categorical-color-03-default-transparent': '#00e5c6cc',
    '--dt-colors-charts-categorical-color-03-hover-transparent': '#55fce6cc',
    '--dt-colors-charts-categorical-color-03-active-transparent': '#c3fff9cc',
    '--dt-colors-charts-categorical-color-04-default': '#e670a1',
    '--dt-colors-charts-categorical-color-04-hover': '#eb8eb5',
    '--dt-colors-charts-categorical-color-04-active': '#f0a8c6',
    '--dt-colors-charts-categorical-color-04-default-transparent': '#ff86b8cc',
    '--dt-colors-charts-categorical-color-04-hover-transparent': '#ffaed0cc',
    '--dt-colors-charts-categorical-color-04-active-transparent': '#ffd1e4cc',
    '--dt-colors-charts-categorical-color-05-default': '#819bb1',
    '--dt-colors-charts-categorical-color-05-hover': '#9aafc1',
    '--dt-colors-charts-categorical-color-05-active': '#b3c3d1',
    '--dt-colors-charts-categorical-color-05-default-transparent': '#94b4cecc',
    '--dt-colors-charts-categorical-color-05-hover-transparent': '#b3cee1cc',
    '--dt-colors-charts-categorical-color-05-active-transparent': '#d2e7f5cc',
    '--dt-colors-charts-categorical-color-06-default': '#f5be3d',
    '--dt-colors-charts-categorical-color-06-hover': '#f7cb64',
    '--dt-colors-charts-categorical-color-06-active': '#f9da8b',
    '--dt-colors-charts-categorical-color-06-default-transparent': '#ffe8b7cc',
    '--dt-colors-charts-categorical-color-06-hover-transparent': '#fff8e3cc',
    '--dt-colors-charts-categorical-color-06-active-transparent': '#fffc',
    '--dt-colors-charts-categorical-color-07-default': '#119795',
    '--dt-colors-charts-categorical-color-07-hover': '#41aaa8',
    '--dt-colors-charts-categorical-color-07-active': '#70c2c0',
    '--dt-colors-charts-categorical-color-07-default-transparent': '#08b0aacc',
    '--dt-colors-charts-categorical-color-07-hover-transparent': '#44c8c2cc',
    '--dt-colors-charts-categorical-color-07-active-transparent': '#7fe5e0cc',
    '--dt-colors-charts-categorical-color-08-default': '#a885f9',
    '--dt-colors-charts-categorical-color-08-hover': '#ba9efa',
    '--dt-colors-charts-categorical-color-08-active': '#cbb6fb',
    '--dt-colors-charts-categorical-color-08-default-transparent': '#c3a0ffcc',
    '--dt-colors-charts-categorical-color-08-hover-transparent': '#d7bfffcc',
    '--dt-colors-charts-categorical-color-08-active-transparent': '#ebddffcc',
    '--dt-colors-charts-categorical-color-09-default': '#45ba5a',
    '--dt-colors-charts-categorical-color-09-hover': '#7dcf8c',
    '--dt-colors-charts-categorical-color-09-active': '#a2ddad',
    '--dt-colors-charts-categorical-color-09-default-transparent': '#49dc61cc',
    '--dt-colors-charts-categorical-color-09-hover-transparent': '#8ff69ecc',
    '--dt-colors-charts-categorical-color-09-active-transparent': '#bfffc8cc',
    '--dt-colors-charts-categorical-color-10-default': '#c96d3b',
    '--dt-colors-charts-categorical-color-10-hover': '#d48b63',
    '--dt-colors-charts-categorical-color-10-active': '#dfa88b',
    '--dt-colors-charts-categorical-color-10-default-transparent': '#ef7b39cc',
    '--dt-colors-charts-categorical-color-10-hover-transparent': '#fca06bcc',
    '--dt-colors-charts-categorical-color-10-active-transparent': '#ffc6a1cc',
    '--dt-colors-charts-categorical-color-11-default': '#3db5f5',
    '--dt-colors-charts-categorical-color-11-hover': '#64c3f7',
    '--dt-colors-charts-categorical-color-11-active': '#8bd2f9',
    '--dt-colors-charts-categorical-color-11-default-transparent': '#7ed1ffcc',
    '--dt-colors-charts-categorical-color-11-hover-transparent': '#a6e2ffcc',
    '--dt-colors-charts-categorical-color-11-active-transparent': '#d3f2ffcc',
    '--dt-colors-charts-categorical-color-12-default': '#ed4a55',
    '--dt-colors-charts-categorical-color-12-hover': '#f16f78',
    '--dt-colors-charts-categorical-color-12-active': '#f49096',
    '--dt-colors-charts-categorical-color-12-default-transparent': '#ff6464cc',
    '--dt-colors-charts-categorical-color-12-hover-transparent': '#ff8e8fcc',
    '--dt-colors-charts-categorical-color-12-active-transparent': '#ffb5b6cc',
    '--dt-colors-charts-categorical-color-13-default': '#7b8d07',
    '--dt-colors-charts-categorical-color-13-hover': '#94a239',
    '--dt-colors-charts-categorical-color-13-active': '#afba69',
    '--dt-colors-charts-categorical-color-13-default-transparent': '#8da400cc',
    '--dt-colors-charts-categorical-color-13-hover-transparent': '#acbe37cc',
    '--dt-colors-charts-categorical-color-13-active-transparent': '#cedb73cc',
    '--dt-colors-charts-categorical-color-14-default': '#f07642',
    '--dt-colors-charts-categorical-color-14-hover': '#f39268',
    '--dt-colors-charts-categorical-color-14-active': '#f6ad8e',
    '--dt-colors-charts-categorical-color-14-default-transparent': '#ff9560cc',
    '--dt-colors-charts-categorical-color-14-hover-transparent': '#ffb690cc',
    '--dt-colors-charts-categorical-color-14-active-transparent': '#ffd8c2cc',
    '--dt-colors-charts-categorical-color-15-default': '#c255e7',
    '--dt-colors-charts-categorical-color-15-hover': '#cd7aeb',
    '--dt-colors-charts-categorical-color-15-active': '#da99f0',
    '--dt-colors-charts-categorical-color-15-default-transparent': '#e461ffcc',
    '--dt-colors-charts-categorical-color-15-hover-transparent': '#ed92ffcc',
    '--dt-colors-charts-categorical-color-15-active-transparent': '#f7bbffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-default':
        '#7c7cdf',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-hover': '#9595e4',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-active':
        '#b1b1ec',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-default-transparent':
        '#8e8effcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-hover-transparent':
        '#adadffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-01-active-transparent':
        '#d2d1ffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-default':
        '#d241a2',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-hover': '#db66b4',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-active':
        '#e48bc6',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-default-transparent':
        '#fa44bacc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-hover-transparent':
        '#ff73d1cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-02-active-transparent':
        '#ffa5e5cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-default':
        '#a885f9',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-hover': '#ba9efa',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-active':
        '#cbb6fb',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-default-transparent':
        '#c3a0ffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-hover-transparent':
        '#d7bfffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-03-active-transparent':
        '#ebddffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-default':
        '#e670a1',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-hover': '#eb8eb5',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-active':
        '#f0a8c6',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-default-transparent':
        '#ff86b8cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-hover-transparent':
        '#ffaed0cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-04-active-transparent':
        '#ffd1e4cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-default':
        '#c255e7',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-hover': '#cd7aeb',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-active':
        '#da99f0',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-default-transparent':
        '#e461ffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-hover-transparent':
        '#ed92ffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-05-active-transparent':
        '#f7bbffcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-default':
        '#ed4a55',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-hover': '#f16f78',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-active':
        '#f49096',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-default-transparent':
        '#ff6464cc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-hover-transparent':
        '#ff8e8fcc',
    '--dt-colors-charts-categorical-themed-purple-rain-color-06-active-transparent':
        '#ffb5b6cc',
    '--dt-colors-charts-categorical-themed-swamps-color-01-default': '#00c2ab',
    '--dt-colors-charts-categorical-themed-swamps-color-01-hover': '#4ed4c5',
    '--dt-colors-charts-categorical-themed-swamps-color-01-active': '#7fe1d6',
    '--dt-colors-charts-categorical-themed-swamps-color-01-default-transparent':
        '#00e5c6cc',
    '--dt-colors-charts-categorical-themed-swamps-color-01-hover-transparent':
        '#55fce6cc',
    '--dt-colors-charts-categorical-themed-swamps-color-01-active-transparent':
        '#c3fff9cc',
    '--dt-colors-charts-categorical-themed-swamps-color-02-default': '#819bb1',
    '--dt-colors-charts-categorical-themed-swamps-color-02-hover': '#9aafc1',
    '--dt-colors-charts-categorical-themed-swamps-color-02-active': '#b3c3d1',
    '--dt-colors-charts-categorical-themed-swamps-color-02-default-transparent':
        '#94b4cecc',
    '--dt-colors-charts-categorical-themed-swamps-color-02-hover-transparent':
        '#b3cee1cc',
    '--dt-colors-charts-categorical-themed-swamps-color-02-active-transparent':
        '#d2e7f5cc',
    '--dt-colors-charts-categorical-themed-swamps-color-03-default': '#7b8d07',
    '--dt-colors-charts-categorical-themed-swamps-color-03-hover': '#94a239',
    '--dt-colors-charts-categorical-themed-swamps-color-03-active': '#afba69',
    '--dt-colors-charts-categorical-themed-swamps-color-03-default-transparent':
        '#8da400cc',
    '--dt-colors-charts-categorical-themed-swamps-color-03-hover-transparent':
        '#acbe37cc',
    '--dt-colors-charts-categorical-themed-swamps-color-03-active-transparent':
        '#cedb73cc',
    '--dt-colors-charts-categorical-themed-swamps-color-04-default': '#119795',
    '--dt-colors-charts-categorical-themed-swamps-color-04-hover': '#41aaa8',
    '--dt-colors-charts-categorical-themed-swamps-color-04-active': '#70c2c0',
    '--dt-colors-charts-categorical-themed-swamps-color-04-default-transparent':
        '#08b0aacc',
    '--dt-colors-charts-categorical-themed-swamps-color-04-hover-transparent':
        '#44c8c2cc',
    '--dt-colors-charts-categorical-themed-swamps-color-04-active-transparent':
        '#7fe5e0cc',
    '--dt-colors-charts-categorical-themed-swamps-color-05-default': '#45ba5a',
    '--dt-colors-charts-categorical-themed-swamps-color-05-hover': '#7dcf8c',
    '--dt-colors-charts-categorical-themed-swamps-color-05-active': '#a2ddad',
    '--dt-colors-charts-categorical-themed-swamps-color-05-default-transparent':
        '#49dc61cc',
    '--dt-colors-charts-categorical-themed-swamps-color-05-hover-transparent':
        '#8ff69ecc',
    '--dt-colors-charts-categorical-themed-swamps-color-05-active-transparent':
        '#bfffc8cc',
    '--dt-colors-charts-categorical-themed-swamps-color-06-default': '#e5e9ff',
    '--dt-colors-charts-categorical-themed-swamps-color-06-hover': '#cdd1e5',
    '--dt-colors-charts-categorical-themed-swamps-color-06-active': '#b7bbcc',
    '--dt-colors-charts-categorical-themed-swamps-color-06-default-transparent':
        '#fffc',
    '--dt-colors-charts-categorical-themed-swamps-color-06-hover-transparent':
        '#f5f8ffcc',
    '--dt-colors-charts-categorical-themed-swamps-color-06-active-transparent':
        '#d8dcefcc',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-default': '#ed4a55',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-hover': '#f16f78',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-active': '#f49096',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-default-transparent':
        '#ff6464cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-hover-transparent':
        '#ff8e8fcc',
    '--dt-colors-charts-categorical-themed-fireplace-color-01-active-transparent':
        '#ffb5b6cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-default': '#e670a1',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-hover': '#eb8eb5',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-active': '#f0a8c6',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-default-transparent':
        '#ff86b8cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-hover-transparent':
        '#ffaed0cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-02-active-transparent':
        '#ffd1e4cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-default': '#f07642',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-hover': '#f39268',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-active': '#f6ad8e',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-default-transparent':
        '#ff9560cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-hover-transparent':
        '#ffb690cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-03-active-transparent':
        '#ffd8c2cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-default': '#f5be3d',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-hover': '#f7cb64',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-active': '#f9da8b',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-default-transparent':
        '#ffe8b7cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-hover-transparent':
        '#fff8e3cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-04-active-transparent':
        '#fffc',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-default': '#c96d3b',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-hover': '#d48b63',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-active': '#dfa88b',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-default-transparent':
        '#ef7b39cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-hover-transparent':
        '#fca06bcc',
    '--dt-colors-charts-categorical-themed-fireplace-color-05-active-transparent':
        '#ffc6a1cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-default': '#d241a2',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-hover': '#db66b4',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-active': '#e48bc6',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-default-transparent':
        '#fa44bacc',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-hover-transparent':
        '#ff73d1cc',
    '--dt-colors-charts-categorical-themed-fireplace-color-06-active-transparent':
        '#ffa5e5cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-default':
        '#819bb1',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-hover': '#9aafc1',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-active': '#b3c3d1',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-default-transparent':
        '#94b4cecc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-hover-transparent':
        '#b3cee1cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-01-active-transparent':
        '#d2e7f5cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-default':
        '#61b0db',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-hover': '#82c3e3',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-active': '#a0cfe9',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-default-transparent':
        '#6cd0ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-hover-transparent':
        '#a0e5ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-02-active-transparent':
        '#d0f3ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-default':
        '#6c86ac',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-hover': '#97a9c4',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-active': '#c0ccdd',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-default-transparent':
        '#7a9ac7cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-hover-transparent':
        '#afc6e5cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-03-active-transparent':
        '#e4f2ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-default':
        '#7d8597',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-hover': '#9ca3b0',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-active': '#bec2cb',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-default-transparent':
        '#8f99accc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-hover-transparent':
        '#b6bfcbcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-04-active-transparent':
        '#e0e5edcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-default':
        '#638ecf',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-hover': '#82a5d9',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-active': '#a1bce2',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-default-transparent':
        '#6fa5f3cc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-hover-transparent':
        '#96c1ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-05-active-transparent':
        '#c0deffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-default':
        '#e5e9ff',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-hover': '#cdd1e5',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-active': '#b7bbcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-default-transparent':
        '#fffc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-hover-transparent':
        '#f5f8ffcc',
    '--dt-colors-charts-categorical-themed-blue-steel-color-06-active-transparent':
        '#d8dcefcc',
    '--dt-colors-charts-logstatus-error-default': '#ed4a55',
    '--dt-colors-charts-logstatus-error-hover': '#f16f78',
    '--dt-colors-charts-logstatus-error-active': '#f49096',
    '--dt-colors-charts-logstatus-error-default-transparent': '#ff6464cc',
    '--dt-colors-charts-logstatus-error-hover-transparent': '#ff8e8fcc',
    '--dt-colors-charts-logstatus-error-active-transparent': '#ffb5b6cc',
    '--dt-colors-charts-logstatus-warning-default': '#f5be3d',
    '--dt-colors-charts-logstatus-warning-hover': '#f7cb64',
    '--dt-colors-charts-logstatus-warning-active': '#f9da8b',
    '--dt-colors-charts-logstatus-warning-default-transparent': '#ffe8b7cc',
    '--dt-colors-charts-logstatus-warning-hover-transparent': '#fff8e3cc',
    '--dt-colors-charts-logstatus-warning-active-transparent': '#fffc',
    '--dt-colors-charts-logstatus-info-default': '#7c7cdf',
    '--dt-colors-charts-logstatus-info-hover': '#9595e4',
    '--dt-colors-charts-logstatus-info-active': '#b1b1ec',
    '--dt-colors-charts-logstatus-info-default-transparent': '#8e8effcc',
    '--dt-colors-charts-logstatus-info-hover-transparent': '#adadffcc',
    '--dt-colors-charts-logstatus-info-active-transparent': '#d2d1ffcc',
    '--dt-colors-charts-logstatus-none-default': '#a885f9',
    '--dt-colors-charts-logstatus-none-hover': '#ba9efa',
    '--dt-colors-charts-logstatus-none-active': '#cbb6fb',
    '--dt-colors-charts-logstatus-none-default-transparent': '#c3a0ffcc',
    '--dt-colors-charts-logstatus-none-hover-transparent': '#d7bfffcc',
    '--dt-colors-charts-logstatus-none-active-transparent': '#ebddffcc',
    '--dt-colors-charts-loglevel-emergency-default': '#ed4a55',
    '--dt-colors-charts-loglevel-emergency-hover': '#f16f78',
    '--dt-colors-charts-loglevel-emergency-active': '#f49096',
    '--dt-colors-charts-loglevel-emergency-default-transparent': '#ff6464cc',
    '--dt-colors-charts-loglevel-emergency-hover-transparent': '#ff8e8fcc',
    '--dt-colors-charts-loglevel-emergency-active-transparent': '#ffb5b6cc',
    '--dt-colors-charts-loglevel-alert-default': '#d241a2',
    '--dt-colors-charts-loglevel-alert-hover': '#db66b4',
    '--dt-colors-charts-loglevel-alert-active': '#e48bc6',
    '--dt-colors-charts-loglevel-alert-default-transparent': '#fa44bacc',
    '--dt-colors-charts-loglevel-alert-hover-transparent': '#ff73d1cc',
    '--dt-colors-charts-loglevel-alert-active-transparent': '#ffa5e5cc',
    '--dt-colors-charts-loglevel-critical-default': '#e670a1',
    '--dt-colors-charts-loglevel-critical-hover': '#eb8eb5',
    '--dt-colors-charts-loglevel-critical-active': '#f0a8c6',
    '--dt-colors-charts-loglevel-critical-default-transparent': '#ff86b8cc',
    '--dt-colors-charts-loglevel-critical-hover-transparent': '#ffaed0cc',
    '--dt-colors-charts-loglevel-critical-active-transparent': '#ffd1e4cc',
    '--dt-colors-charts-loglevel-severe-default': '#f07642',
    '--dt-colors-charts-loglevel-severe-hover': '#f39268',
    '--dt-colors-charts-loglevel-severe-active': '#f6ad8e',
    '--dt-colors-charts-loglevel-severe-default-transparent': '#ff9560cc',
    '--dt-colors-charts-loglevel-severe-hover-transparent': '#ffb690cc',
    '--dt-colors-charts-loglevel-severe-active-transparent': '#ffd8c2cc',
    '--dt-colors-charts-loglevel-error-default': '#f07642',
    '--dt-colors-charts-loglevel-error-hover': '#f39268',
    '--dt-colors-charts-loglevel-error-active': '#f6ad8e',
    '--dt-colors-charts-loglevel-error-default-transparent': '#ff9560cc',
    '--dt-colors-charts-loglevel-error-hover-transparent': '#ffb690cc',
    '--dt-colors-charts-loglevel-error-active-transparent': '#ffd8c2cc',
    '--dt-colors-charts-loglevel-warning-default': '#f5be3d',
    '--dt-colors-charts-loglevel-warning-hover': '#f7cb64',
    '--dt-colors-charts-loglevel-warning-active': '#f9da8b',
    '--dt-colors-charts-loglevel-warning-default-transparent': '#ffe8b7cc',
    '--dt-colors-charts-loglevel-warning-hover-transparent': '#fff8e3cc',
    '--dt-colors-charts-loglevel-warning-active-transparent': '#fffc',
    '--dt-colors-charts-loglevel-notice-default': '#00c2ab',
    '--dt-colors-charts-loglevel-notice-hover': '#4ed4c5',
    '--dt-colors-charts-loglevel-notice-active': '#7fe1d6',
    '--dt-colors-charts-loglevel-notice-default-transparent': '#00e5c6cc',
    '--dt-colors-charts-loglevel-notice-hover-transparent': '#55fce6cc',
    '--dt-colors-charts-loglevel-notice-active-transparent': '#c3fff9cc',
    '--dt-colors-charts-loglevel-debug-default': '#819bb1',
    '--dt-colors-charts-loglevel-debug-hover': '#9aafc1',
    '--dt-colors-charts-loglevel-debug-active': '#b3c3d1',
    '--dt-colors-charts-loglevel-debug-default-transparent': '#94b4cecc',
    '--dt-colors-charts-loglevel-debug-hover-transparent': '#b3cee1cc',
    '--dt-colors-charts-loglevel-debug-active-transparent': '#d2e7f5cc',
    '--dt-colors-charts-loglevel-info-default': '#7c7cdf',
    '--dt-colors-charts-loglevel-info-hover': '#9595e4',
    '--dt-colors-charts-loglevel-info-active': '#b1b1ec',
    '--dt-colors-charts-loglevel-info-default-transparent': '#8e8effcc',
    '--dt-colors-charts-loglevel-info-hover-transparent': '#adadffcc',
    '--dt-colors-charts-loglevel-info-active-transparent': '#d2d1ffcc',
    '--dt-colors-charts-loglevel-none-default': '#a885f9',
    '--dt-colors-charts-loglevel-none-hover': '#ba9efa',
    '--dt-colors-charts-loglevel-none-active': '#cbb6fb',
    '--dt-colors-charts-loglevel-none-default-transparent': '#c3a0ffcc',
    '--dt-colors-charts-loglevel-none-hover-transparent': '#d7bfffcc',
    '--dt-colors-charts-loglevel-none-active-transparent': '#ebddffcc',
    '--dt-colors-charts-apdex-excellent-default': '#00c2ab',
    '--dt-colors-charts-apdex-excellent-hover': '#4ed4c5',
    '--dt-colors-charts-apdex-excellent-active': '#7fe1d6',
    '--dt-colors-charts-apdex-excellent-default-transparent': '#00e5c6cc',
    '--dt-colors-charts-apdex-excellent-hover-transparent': '#55fce6cc',
    '--dt-colors-charts-apdex-excellent-active-transparent': '#c3fff9cc',
    '--dt-colors-charts-apdex-good-default': '#45ba5a',
    '--dt-colors-charts-apdex-good-hover': '#7dcf8c',
    '--dt-colors-charts-apdex-good-active': '#a2ddad',
    '--dt-colors-charts-apdex-good-default-transparent': '#49dc61cc',
    '--dt-colors-charts-apdex-good-hover-transparent': '#8ff69ecc',
    '--dt-colors-charts-apdex-good-active-transparent': '#bfffc8cc',
    '--dt-colors-charts-apdex-fair-default': '#f5be3d',
    '--dt-colors-charts-apdex-fair-hover': '#f7cb64',
    '--dt-colors-charts-apdex-fair-active': '#f9da8b',
    '--dt-colors-charts-apdex-fair-default-transparent': '#ffe8b7cc',
    '--dt-colors-charts-apdex-fair-hover-transparent': '#fff8e3cc',
    '--dt-colors-charts-apdex-fair-active-transparent': '#fffc',
    '--dt-colors-charts-apdex-poor-default': '#f07642',
    '--dt-colors-charts-apdex-poor-hover': '#f39268',
    '--dt-colors-charts-apdex-poor-active': '#f6ad8e',
    '--dt-colors-charts-apdex-poor-default-transparent': '#ff9560cc',
    '--dt-colors-charts-apdex-poor-hover-transparent': '#ffb690cc',
    '--dt-colors-charts-apdex-poor-active-transparent': '#ffd8c2cc',
    '--dt-colors-charts-apdex-unacceptable-default': '#ed4a55',
    '--dt-colors-charts-apdex-unacceptable-hover': '#f16f78',
    '--dt-colors-charts-apdex-unacceptable-active': '#f49096',
    '--dt-colors-charts-apdex-unacceptable-default-transparent': '#ff6464cc',
    '--dt-colors-charts-apdex-unacceptable-hover-transparent': '#ff8e8fcc',
    '--dt-colors-charts-apdex-unacceptable-active-transparent': '#ffb5b6cc',
    '--dt-colors-charts-vulnerability-risk-level-critical-default': '#ff0d00',
    '--dt-colors-charts-vulnerability-risk-level-critical-hover': '#ff4c42',
    '--dt-colors-charts-vulnerability-risk-level-critical-active': '#ff6e66',
    '--dt-colors-charts-vulnerability-risk-level-critical-default-transparent':
        '#ff6239cc',
    '--dt-colors-charts-vulnerability-risk-level-critical-hover-transparent':
        '#ff7c65cc',
    '--dt-colors-charts-vulnerability-risk-level-critical-active-transparent':
        '#ff998acc',
    '--dt-colors-charts-vulnerability-risk-level-high-default': '#ed4a55',
    '--dt-colors-charts-vulnerability-risk-level-high-hover': '#f16f78',
    '--dt-colors-charts-vulnerability-risk-level-high-active': '#f49096',
    '--dt-colors-charts-vulnerability-risk-level-high-default-transparent':
        '#ff6464cc',
    '--dt-colors-charts-vulnerability-risk-level-high-hover-transparent':
        '#ff8e8fcc',
    '--dt-colors-charts-vulnerability-risk-level-high-active-transparent':
        '#ffb5b6cc',
    '--dt-colors-charts-vulnerability-risk-level-medium-default': '#f5be3d',
    '--dt-colors-charts-vulnerability-risk-level-medium-hover': '#f7cb64',
    '--dt-colors-charts-vulnerability-risk-level-medium-active': '#f9da8b',
    '--dt-colors-charts-vulnerability-risk-level-medium-default-transparent':
        '#ffe8b7cc',
    '--dt-colors-charts-vulnerability-risk-level-medium-hover-transparent':
        '#fff8e3cc',
    '--dt-colors-charts-vulnerability-risk-level-medium-active-transparent':
        '#fffc',
    '--dt-colors-charts-vulnerability-risk-level-low-default': '#7c7cdf',
    '--dt-colors-charts-vulnerability-risk-level-low-hover': '#9595e4',
    '--dt-colors-charts-vulnerability-risk-level-low-active': '#b1b1ec',
    '--dt-colors-charts-vulnerability-risk-level-low-default-transparent':
        '#8e8effcc',
    '--dt-colors-charts-vulnerability-risk-level-low-hover-transparent':
        '#adadffcc',
    '--dt-colors-charts-vulnerability-risk-level-low-active-transparent':
        '#d2d1ffcc',
    '--dt-colors-charts-grid-magnifier-default': '#7d8597',
    '--dt-colors-charts-grid-magnifier-hover': '#9ca3b0',
    '--dt-colors-charts-grid-magnifier-active': '#bec2cb',
    '--dt-colors-charts-grid-magnifier-text': '#1b1b32',
    '--dt-colors-charts-grid-crosshair-default': '#7d8597',
    '--dt-colors-charts-grid-crosshair-hover': '#9ca3b0',
    '--dt-colors-charts-grid-crosshair-active': '#bec2cb',
    '--dt-colors-charts-grid-crosshair-text': '#1b1b32',
    '--dt-colors-charts-threshold-good-default': '#00c2ab',
    '--dt-colors-charts-threshold-warning-default': '#f5be3d',
    '--dt-colors-charts-threshold-bad-default': '#ff7d7a',
    /** Use as the default layer, for elements that do not need to be explicitly layered on top of other elements. */
    '--dt-elevations-default': '1',
    /** Use for elements that are pinned to a certain location while also staying on top of other content. */
    '--dt-elevations-sticky': '10',
    /** Use for elements that appear on top of all other content on the page. */
    '--dt-elevations-overlay': '20',
    /** Use for notifications that should always be visible on top of other content. */
    '--dt-elevations-notification': '1999999999',
    '--dt-spacings-size-0': '0',
    '--dt-spacings-size-2': '2px',
    '--dt-spacings-size-4': '4px',
    '--dt-spacings-size-6': '6px',
    '--dt-spacings-size-8': '8px',
    '--dt-spacings-size-12': '12px',
    '--dt-spacings-size-16': '16px',
    '--dt-spacings-size-20': '20px',
    '--dt-spacings-size-24': '24px',
    '--dt-spacings-size-28': '28px',
    '--dt-spacings-size-32': '32px',
    '--dt-spacings-size-36': '36px',
    '--dt-spacings-size-40': '40px',
    '--dt-spacings-size-48': '48px',
    '--dt-spacings-size-56': '56px',
    '--dt-spacings-size-64': '64px',
    '--dt-typography-display-level-1-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-display-level-1-weight': '600',
    '--dt-typography-display-level-1-size': '64px',
    '--dt-typography-display-level-1-line-height': '1.1875',
    '--dt-typography-display-level-1-text-decoration': 'none',
    '--dt-typography-display-level-1-text-transform': 'none',
    '--dt-typography-display-level-2-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-display-level-2-weight': '600',
    '--dt-typography-display-level-2-size': '56px',
    '--dt-typography-display-level-2-line-height': '1.2142857143',
    '--dt-typography-display-level-2-text-decoration': 'none',
    '--dt-typography-display-level-2-text-transform': 'none',
    '--dt-typography-display-level-3-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-display-level-3-weight': '600',
    '--dt-typography-display-level-3-size': '48px',
    '--dt-typography-display-level-3-line-height': '1.25',
    '--dt-typography-display-level-3-text-decoration': 'none',
    '--dt-typography-display-level-3-text-transform': 'none',
    '--dt-typography-heading-level-1-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-1-weight': '600',
    '--dt-typography-heading-level-1-size': '32px',
    '--dt-typography-heading-level-1-line-height': '1.25',
    '--dt-typography-heading-level-1-text-decoration': 'none',
    '--dt-typography-heading-level-1-text-transform': 'none',
    '--dt-typography-heading-level-2-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-2-weight': '600',
    '--dt-typography-heading-level-2-size': '28px',
    '--dt-typography-heading-level-2-line-height': '1.2857142857',
    '--dt-typography-heading-level-2-text-decoration': 'none',
    '--dt-typography-heading-level-2-text-transform': 'none',
    '--dt-typography-heading-level-3-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-3-weight': '600',
    '--dt-typography-heading-level-3-size': '24px',
    '--dt-typography-heading-level-3-line-height': '1.3333333333',
    '--dt-typography-heading-level-3-text-decoration': 'none',
    '--dt-typography-heading-level-3-text-transform': 'none',
    '--dt-typography-heading-level-4-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-4-weight': '600',
    '--dt-typography-heading-level-4-size': '20px',
    '--dt-typography-heading-level-4-line-height': '1.4',
    '--dt-typography-heading-level-4-text-decoration': 'none',
    '--dt-typography-heading-level-4-text-transform': 'none',
    '--dt-typography-heading-level-5-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-5-weight': '600',
    '--dt-typography-heading-level-5-size': '16px',
    '--dt-typography-heading-level-5-line-height': '1.5',
    '--dt-typography-heading-level-5-text-decoration': 'none',
    '--dt-typography-heading-level-5-text-transform': 'none',
    '--dt-typography-heading-level-6-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-heading-level-6-weight': '600',
    '--dt-typography-heading-level-6-size': '14px',
    '--dt-typography-heading-level-6-line-height': '1.4285714286',
    '--dt-typography-heading-level-6-text-decoration': 'none',
    '--dt-typography-heading-level-6-text-transform': 'none',
    '--dt-typography-subtitle-display-level-1-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-display-level-1-weight': '400',
    '--dt-typography-subtitle-display-level-1-size': '28px',
    '--dt-typography-subtitle-display-level-1-line-height': '1.4285714286',
    '--dt-typography-subtitle-display-level-1-text-decoration': 'none',
    '--dt-typography-subtitle-display-level-1-text-transform': 'none',
    '--dt-typography-subtitle-display-level-2-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-display-level-2-weight': '400',
    '--dt-typography-subtitle-display-level-2-size': '24px',
    '--dt-typography-subtitle-display-level-2-line-height': '1.5',
    '--dt-typography-subtitle-display-level-2-text-decoration': 'none',
    '--dt-typography-subtitle-display-level-2-text-transform': 'none',
    '--dt-typography-subtitle-display-level-3-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-display-level-3-weight': '400',
    '--dt-typography-subtitle-display-level-3-size': '21px',
    '--dt-typography-subtitle-display-level-3-line-height': '1.5238095238',
    '--dt-typography-subtitle-display-level-3-text-decoration': 'none',
    '--dt-typography-subtitle-display-level-3-text-transform': 'none',
    '--dt-typography-subtitle-heading-level-1-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-heading-level-1-weight': '400',
    '--dt-typography-subtitle-heading-level-1-size': '21px',
    '--dt-typography-subtitle-heading-level-1-line-height': '1.5238095238',
    '--dt-typography-subtitle-heading-level-1-text-decoration': 'none',
    '--dt-typography-subtitle-heading-level-1-text-transform': 'none',
    '--dt-typography-subtitle-heading-level-2-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-heading-level-2-weight': '400',
    '--dt-typography-subtitle-heading-level-2-size': '18px',
    '--dt-typography-subtitle-heading-level-2-line-height': '1.5555555556',
    '--dt-typography-subtitle-heading-level-2-text-decoration': 'none',
    '--dt-typography-subtitle-heading-level-2-text-transform': 'none',
    '--dt-typography-subtitle-heading-level-3-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-subtitle-heading-level-3-weight': '400',
    '--dt-typography-subtitle-heading-level-3-size': '16px',
    '--dt-typography-subtitle-heading-level-3-line-height': '1.5',
    '--dt-typography-subtitle-heading-level-3-text-decoration': 'none',
    '--dt-typography-subtitle-heading-level-3-text-transform': 'none',
    '--dt-typography-text-base-default-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-text-base-default-weight': '400',
    '--dt-typography-text-base-default-size': '14px',
    '--dt-typography-text-base-default-line-height': '1.4285714286',
    '--dt-typography-text-base-default-text-decoration': 'none',
    '--dt-typography-text-base-default-text-transform': 'none',
    '--dt-typography-text-base-emphasized-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-text-base-emphasized-weight': '500',
    '--dt-typography-text-base-emphasized-size': '14px',
    '--dt-typography-text-base-emphasized-line-height': '1.4285714286',
    '--dt-typography-text-base-emphasized-text-decoration': 'none',
    '--dt-typography-text-base-emphasized-text-transform': 'none',
    '--dt-typography-text-small-default-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-text-small-default-weight': '400',
    '--dt-typography-text-small-default-size': '12px',
    '--dt-typography-text-small-default-line-height': '1.333333333',
    '--dt-typography-text-small-default-text-decoration': 'none',
    '--dt-typography-text-small-default-text-transform': 'none',
    '--dt-typography-text-small-emphasized-family':
        'DynatraceFlow,Roboto,Helvetica,sans-serif',
    '--dt-typography-text-small-emphasized-weight': '500',
    '--dt-typography-text-small-emphasized-size': '12px',
    '--dt-typography-text-small-emphasized-line-height': '1.333333333',
    '--dt-typography-text-small-emphasized-text-decoration': 'none',
    '--dt-typography-text-small-emphasized-text-transform': 'none',
    '--dt-typography-code-base-default-family': 'Roboto Mono,monospace',
    '--dt-typography-code-base-default-weight': '400',
    '--dt-typography-code-base-default-size': '14px',
    '--dt-typography-code-base-default-line-height': '1.4285714286',
    '--dt-typography-code-base-default-text-decoration': 'none',
    '--dt-typography-code-base-default-text-transform': 'none',
    '--dt-typography-code-base-emphasized-family': 'Roboto Mono,monospace',
    '--dt-typography-code-base-emphasized-weight': '500',
    '--dt-typography-code-base-emphasized-size': '14px',
    '--dt-typography-code-base-emphasized-line-height': '1.4285714286',
    '--dt-typography-code-base-emphasized-text-decoration': 'none',
    '--dt-typography-code-base-emphasized-text-transform': 'none',
    '--dt-typography-code-small-default-family': 'Roboto Mono,monospace',
    '--dt-typography-code-small-default-weight': '400',
    '--dt-typography-code-small-default-size': '12px',
    '--dt-typography-code-small-default-line-height': '1.333333333',
    '--dt-typography-code-small-default-text-decoration': 'none',
    '--dt-typography-code-small-default-text-transform': 'none',
    '--dt-typography-code-small-emphasized-family': 'Roboto Mono,monospace',
    '--dt-typography-code-small-emphasized-weight': '500',
    '--dt-typography-code-small-emphasized-size': '12px',
    '--dt-typography-code-small-emphasized-line-height': '1.333333333',
    '--dt-typography-code-small-emphasized-text-decoration': 'none',
    '--dt-typography-code-small-emphasized-text-transform': 'none',
    '--dt-animations-open-duration-fast': '170ms',
    '--dt-animations-open-duration-base': '250ms',
    '--dt-animations-open-duration-slow': '400ms',
    '--dt-animations-open-easing': 'cubic-bezier(0, 0, 0.2, 1)',
    '--dt-animations-close-duration-fast': '0ms',
    '--dt-animations-close-duration-base': '170ms',
    '--dt-animations-close-duration-slow': '250ms',
    '--dt-animations-close-easing': 'cubic-bezier(0.5, 0, 1, 1)',
    '--dt-animations-slide-in-duration-fast': '200ms',
    '--dt-animations-slide-in-duration-base': '300ms',
    '--dt-animations-slide-in-duration-slow': '400ms',
    '--dt-animations-slide-in-easing': 'cubic-bezier(0, 0, 0.2, 1)',
    '--dt-animations-slide-out-duration-fast': '150ms',
    '--dt-animations-slide-out-duration-base': '250ms',
    '--dt-animations-slide-out-duration-slow': '350ms',
    '--dt-animations-slide-out-easing': 'cubic-bezier(0.8, 0, 0.5, 1)',
    '--dt-animations-fade-in-duration-fast': '150ms',
    '--dt-animations-fade-in-duration-base': '250ms',
    '--dt-animations-fade-in-duration-slow': '350ms',
    '--dt-animations-fade-in-easing': 'cubic-bezier(0.5, 0, 1, 1)',
    '--dt-animations-fade-out-duration-fast': '150ms',
    '--dt-animations-fade-out-duration-base': '250ms',
    '--dt-animations-fade-out-duration-slow': '350ms',
    '--dt-animations-fade-out-easing': 'cubic-bezier(0, 0, 0.2, 1)',
    '--dt-animations-hover-duration-base': '150ms',
    '--dt-animations-hover-easing': 'cubic-bezier(0, 0, 0.2, 1)',
    '--dt-animations-staggered-motion-duration-fast': '250ms',
    '--dt-animations-staggered-motion-duration-base': '350ms',
    '--dt-animations-staggered-motion-duration-slow': '400ms',
    '--dt-animations-staggered-motion-offset-fast': '20ms',
    '--dt-animations-staggered-motion-offset-base': '30ms',
    '--dt-animations-staggered-motion-offset-slow': '50ms',
    '--dt-animations-staggered-motion-easing': 'cubic-bezier(0, 0, 0.2, 1)',
    '--dt-animations-expand-collapse-duration-fast': '250ms',
    '--dt-animations-expand-collapse-duration-base': '400ms',
    '--dt-animations-expand-collapse-duration-slow': '600ms',
    '--dt-animations-expand-collapse-easing': 'cubic-bezier(0.4, 0, 0.2, 1)',
    '--dt-animations-move-duration-fast': '150ms',
    '--dt-animations-move-duration-base': '400ms',
    '--dt-animations-move-duration-slow': '600ms',
    '--dt-animations-move-easing': 'cubic-bezier(0.4, 0, 0.2, 1)',
    '--dt-animations-rescale-duration-fast': '170ms',
    '--dt-animations-rescale-duration-base': '250ms',
    '--dt-animations-rescale-duration-slow': '400ms',
    '--dt-animations-rescale-easing': 'cubic-bezier(0.4, 0, 0.2, 1)',
    /** Use to convey the deceleration of an object, such as when it's coming to a stop. It starts at a fast pace and then slows down at the end. */
    '--dt-easings-decelerate': 'cubic-bezier(0, 0, 0.2, 1)',
    /** Use to convey the acceleration of an object, such as when it's being launched. It starts at a slow pace and then speeds up at the end. */
    '--dt-easings-accelerate': 'cubic-bezier(0.5, 0, 1, 1)',
    /** Use to draw attention to an object. It starts slowly, accelerates in the middle, and then slows down again at the end. */
    '--dt-easings-slow-in-fast-out': 'cubic-bezier(0.8, 0, 0.5, 1)',
    /** Use to move or scale objects. It starts slowly, quickly accelerates in the middle, and slows down at the end. */
    '--dt-easings-fast-in-slow-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    /** Use to convey a playful or elastic effect, such as when an object bounces or stretches. It overshoots its target position before settling back into place. */
    '--dt-easings-out-back': 'cubic-bezier(0.4, 1.6, 0.7, 1)',
    '--dt-timings-instant-default': '0ms',
    '--dt-timings-offset-short': '20ms',
    '--dt-timings-offset-medium': '30ms',
    '--dt-timings-offset-long': '100ms',
    '--dt-timings-offset-default': '50ms',
    '--dt-timings-fast-quick': '150ms',
    '--dt-timings-fast-default': '170ms',
    '--dt-timings-fast-moderate': '200ms',
    '--dt-timings-base-quick': '250ms',
    '--dt-timings-base-default': '300ms',
    '--dt-timings-base-moderate': '350ms',
    '--dt-timings-slow-default': '400ms',
    '--dt-timings-slow-moderate': '600ms',
    '--dt-timings-slow-gentle': '800ms',
};
export default customPropertiesUnified;
