import React from 'react';
import {DefaultPageLayout} from "./_layouts/DefaultPageLayout";
import {Heading} from "@modbros/components/typography";
import {Flex} from "@modbros/components/layout";

export default function NotFound() {
    return (
        <DefaultPageLayout>
            <Flex justifyContent={'center'} alignItems="center" height="100%" width="100%">
                <Heading level={1} useTag="h1" headingColor="Emphasized" >404</Heading>
            </Flex>
        </DefaultPageLayout>
    );
}

