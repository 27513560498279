import React from 'react';
import { Header } from './parts/Header'
import { Footer } from './parts/Footer';
import {Flex} from "@modbros/components/layout";

export function DefaultPageLayout(props: { children: any; }) {
    const { children } = props
    return (
        <Flex flexDirection="column" justifyContent="space-between" height="100vh" gap={0} style={{scrollbarGutter: 'stable'}}>
            <Header />
            {children}
            <Footer />
        </Flex>
    );
}
