import React from 'react';
import { PageHero } from '../sections/PageHero';
import { Features } from '../sections/Features';
import {DefaultPageLayout} from "./_layouts/DefaultPageLayout";
import {SupportUsOnPatreon} from "./_layouts/parts/SupportUsOnPatreon";

export default function Home() {
    return (
        <DefaultPageLayout>
            <PageHero/>
            <SupportUsOnPatreon />
            <Features />
        </DefaultPageLayout>
    );
}

