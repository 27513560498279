import React from 'react';
import {DefaultPageLayout} from "../_layouts/DefaultPageLayout";
import {HeroHeadline} from "../_layouts/parts/HeroHeadline";

export const Plugins = () => {
    return(
        <DefaultPageLayout>
            <HeroHeadline>
                Plugins
            </HeroHeadline>

        </DefaultPageLayout>
    )
}