import React from 'react';
import './App.css';
import { AppRoot } from '@modbros/components'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Marketplace from "./pages/Marketplace";
import NotFound from "./pages/_NotFound";
import { AppRoot as StratoAppRoot } from '@dynatrace/strato-components-preview/core';
import {DtTokensOverride} from "./utils/DtTokensOverride";
import {DashboardBuilder} from "./pages/feature/DashboardBuilder";
import {WidgetPacks} from "./pages/feature/WidgetPacks";
import {RaspberryPi} from "./pages/feature/RaspberryPi";
import {Plugins} from "./pages/feature/Plugins";
import Pricing from "./pages/Pricing";

function App() {
  return (
      <BrowserRouter >
          <StratoAppRoot>
              <DtTokensOverride />
        <AppRoot theme="dark" >
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/features" element={<Features />}/>
                <Route path="/pricing" element={<Pricing />}/>
                <Route path="/marketplace" element={<Marketplace />}/>

                {/** FEATURES **/}
                <Route path="/feature/dashboard-builder" element={<DashboardBuilder />}/>
                <Route path="/feature/widget-packs" element={<WidgetPacks />}/>
                <Route path="/feature/plugins" element={<Plugins />}/>
                <Route path="/feature/raspberry-pi" element={<RaspberryPi />}/>


                <Route path='*' element={<NotFound />} />
            </Routes>
        </AppRoot>
          </StratoAppRoot>

      </BrowserRouter >
  );
}

export default App;
