import moBroLogo from "./mobro-logo.svg";
import smallMoBroLogo from "./mobro-logo-small.svg";
import React from "react";
import styled from "styled-components";
import Colors from "@modbros/design-tokens/colors"
import { Container, Row, Col, Flex } from '@modbros/components/layout'
import { Text } from '@modbros/components/typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faPatreon } from '@fortawesome/free-brands-svg-icons'
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";

const StyledContainer = styled.header`
  position: sticky;
  top: 0;
  left: 0;

  z-index: 1000;

  background-color: ${Colors.Layer.Highlight};
  box-shadow: 0px 16px 24px rgba(2, 3, 3, 0.03), 0px 6px 30px rgba(2, 3, 3, 0.02), 0px 8px 10px rgba(2, 3, 3, 0.04);
`

export const Header = () => {

    const isAboveMobileSize = useMediaQuery({minWidth: 768})

    return (
            <StyledContainer>
                <Container>
                    <Row>
                        <Col span="auto">
                            <Flex height={54} justifyContent="flex-start" alignItems="center" gap={isAboveMobileSize ? 20 : 8}>
                                <Link to="/">
                                <picture>
                                    <source media="(max-width: 577px)" srcSet={smallMoBroLogo} />
                                    <img height={isAboveMobileSize ? 22 : 32} src={moBroLogo} alt="MoBro-Logo" />
                                </picture>
                                </Link>
                                <Text as={Link} to="/features">FEATURES</Text>
                                {/*<Text as={Link} to="/marketplace">MARKETPLACE</Text>*/}
                                <Text as={Link} to="/pricing">PRICING</Text>
                                <Text as={'a'} target="_blank" href="https://faq.mobro.app/">FAQ</Text>
                                <Text as={'a'} target="_blank" href="https://developer.mobro.app/">DEVELOPERS</Text>
                            </Flex>
                        </Col>
                        <Col>
                            <Flex gap={12} height={54} justifyContent="flex-end" alignItems="center" >
                                <a className="link-unstyled" target="_blank" href="https://discord.gg/DSNX4ds" rel="noreferrer"><FontAwesomeIcon icon={faDiscord} /></a>
                                <a className="link-unstyled" target="_blank" href="https://www.patreon.com/user?u=34439889&fan_landing=true" rel="noreferrer"><FontAwesomeIcon icon={faPatreon} /></a>
                            </Flex>
                        </Col>
                    </Row>
                </Container>
            </StyledContainer>
    )
}